import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { CREATE_NEW_POSITION, UPDATE_POSITION } from "../../shared/apiUrls";
import {
  apiPostAuth,
  apiPatchAuth,
} from "../../shared/hoc/pagesWeb/incidentReporter/utils";
import { defaultUserPermissions } from "../pages/constants";
import { setPositions } from "../redux/positions";

const emptyNewPosition = {
  name: "",
  type: null,
  managerUUID: null,
  defaultPermission: defaultUserPermissions,
};

export default function usePositions(setAddModalVisible) {
  const { positions } = useSelector((state) => state.positions);
  const [newPosition, setNewPosition] = useState(emptyNewPosition);
  const [editPosition, setEditPosition] = useState(null);
  const [newPositionErrors, setNewPositionErrors] = useState({});
  const [editPositionErrors, setEditPositionErrors] = useState({});
  const dispatch = useDispatch();

  const handleUpdateNewPosition = (e) => {
    const { name, value } = e.target;
    setNewPosition((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUpdatePosition = (e) => {
    const { name, value } = e.target;
    setEditPosition((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (callback) => {
    const response = await apiPostAuth(CREATE_NEW_POSITION, newPosition);

    if (response.name) {
      dispatch(setPositions([response, ...positions]));
      setNewPosition(emptyNewPosition);
      setAddModalVisible(false);
      callback();
    }
  };

  const handleUpdate = async (callback) => {
    const response = await apiPatchAuth(UPDATE_POSITION, {
      ...editPosition,
      manager: null,
    });

    if (response.name) {
      const posIdx = positions.findIndex((c) => c.uuid === response.uuid);
      const tempArr = [...positions];
      tempArr[posIdx] = response;

      dispatch(setPositions(tempArr));
      setEditPosition(null);
      setAddModalVisible(false);
      callback();
    }
  };

  const handleSetPositionPermissions = (permissionName) => {
    setNewPosition((prevState) => ({
      ...prevState,
      defaultPermission: {
        ...prevState.defaultPermission,
        [permissionName]: !prevState?.defaultPermission[permissionName],
      },
    }));
  };

  const handleUpdatePositionPermissions = (permissionName) => {
    setEditPosition((prevState) => ({
      ...prevState,
      defaultPermission: {
        ...prevState.defaultPermission,
        [permissionName]: !prevState?.defaultPermission[permissionName],
      },
    }));
  };

  const handleSetEditPosition = (idx) => {
    const edit = positions?.[idx];
    setEditPosition(edit);
    setAddModalVisible(true);
  };

  const handleValidatePosition = () => {
    let errors = false;
    if (!newPosition.name || newPosition.name?.trim() === "") {
      errors = true;
      setNewPositionErrors((prevState) => ({
        ...prevState,
        name: true,
      }));
    } else {
      setNewPositionErrors((prevState) => ({
        ...prevState,
        name: false,
      }));
    }
    if (!newPosition.type) {
      errors = true;
      setNewPositionErrors((prevState) => ({
        ...prevState,
        type: true,
      }));
    } else {
      setNewPositionErrors((prevState) => ({
        ...prevState,
        type: false,
      }));
    }

    return errors;
  };

  const handleValidateEditPosition = () => {
    let errors = false;
    if (!editPosition.name || editPosition.name?.trim() === "") {
      errors = true;
      setEditPositionErrors((prevState) => ({
        ...prevState,
        name: true,
      }));
    } else {
      setEditPositionErrors((prevState) => ({
        ...prevState,
        name: false,
      }));
    }
    if (!editPosition.type) {
      errors = true;
      setEditPositionErrors((prevState) => ({
        ...prevState,
        type: true,
      }));
    } else {
      setEditPositionErrors((prevState) => ({
        ...prevState,
        type: false,
      }));
    }

    return errors;
  };

  return {
    positions,
    newPosition,
    editPosition,
    newPositionErrors,
    editPositionErrors,
    handleSetEditPosition,
    setEditPosition,
    setNewPosition,
    handleUpdateNewPosition,
    handleUpdatePosition,
    handleValidatePosition,
    handleValidateEditPosition,
    handleSubmit,
    handleUpdate,
    handleSetPositionPermissions,
    handleUpdatePositionPermissions,
  };
}
