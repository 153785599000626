/* eslint-disable no-nested-ternary */
import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import icon from "../assets/favicon.ico";
import {
  GET_MY_PERMISSIONS,
  GET_USERS,
  GET_ALL_POSITIONS,
  GET_LOCATIONS,
} from "../shared/apiUrls";
import { apiGetAuth } from "../shared/hoc/pagesWeb/incidentReporter/utils";
import PageLayout from "../src/components/layouts/PageLayout";
import LSSpinner from "../src/components/spinner/LSSpinner";
import Home from "../src/pages/Home";
import Login from "../src/pages/Login";
import Register from "../src/pages/Register";
import Splash from "../src/pages/Splash";
import AssetManagement from "../src/pages/assetManagement/AssetManagement";
import CompanySettings from "../src/pages/companySettings/CompanySettings";
import { urlRoutes } from "../src/pages/constants";
import IncidentsFormPreview from "../src/pages/incidents/IncidentFormPreview";
import IncidentsFormSubmit from "../src/pages/incidents/IncidentReportSubmit";
import IncidentReportsEditing from "../src/pages/incidents/IncidentReportsEditing";
import IncidentsReportsMapping from "../src/pages/incidents/IncidentReportsMapping";
import IncidentsDashboard from "../src/pages/incidents/IncidentsDashboard";
import IncidentsForms from "../src/pages/incidents/IncidentsForms";
import IncidentsReports from "../src/pages/incidents/IncidentsReports";
import SubmittedFormView from "../src/pages/incidents/SubmittedFormView";
import InventoryManagement from "../src/pages/invneotryManagement/InventoryManagement";
import PrivacyPolicy from "../src/pages/legal/PrivacyPolicy";
import TermsOfService from "../src/pages/legal/TermsOfService";
import LocationsDashboard from "../src/pages/locations/LocationsDashboard";
import LocationsSettings from "../src/pages/locations/LocationsSettings";
import PhotoGallery from "../src/pages/photoGallery/PhotoGallery";
import ReceiptTracker from "../src/pages/receiptTracker/ReceiptTracker";
import CompanyPositions from "../src/pages/users/CompanyPositions";
import SetPassword from "../src/pages/users/SetPassword";
import UsersDashboard from "../src/pages/users/UsersDashboard";
import UsersSettings from "../src/pages/users/UsersSettings";
import { setLocations } from "../src/redux/locations";
import { setPermissions } from "../src/redux/permissions";
import { setPositions } from "../src/redux/positions";
import { setUsers } from "../src/redux/users";
import AuditReports from "../src/pages/incidents/AuditReports";
import FormBuilder from "../src/pages/incidents/FormBuilder";

export default function MainWebNavigator() {
  const [isLoadingPermissions, setIsLoadingPermissions] = useState(false);
  const permissions = useSelector((state) => state.permissions);
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem("accessToken");

  const getSetPermissions = async () => {
    const response = await apiGetAuth(GET_MY_PERMISSIONS);
    dispatch(setPermissions(response));
  };

  const getSetUsers = async () => {
    const results = await apiGetAuth(GET_USERS);
    dispatch(setUsers(results));
  };

  const getAllPositions = async () => {
    const results = await apiGetAuth(GET_ALL_POSITIONS);
    dispatch(setPositions(results));
  };

  const getLocations = async () => {
    const results = await apiGetAuth(GET_LOCATIONS);
    dispatch(setLocations(results));
  };

  const makeAllCalls = async () => {
    if (accessToken) {
      setIsLoadingPermissions(true);
      await getSetPermissions();
      await getSetUsers();
      await getAllPositions();
      await getLocations();
      setIsLoadingPermissions(false);
    }
  };

  useEffect(() => {
    makeAllCalls();
    document.title = "LinkStep";
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.head.appendChild(link);
    }
    link.href = icon;
  }, [permissions.userUUID, accessToken]);

  return (
    <Router>
      <Routes>
        {permissions.userUUID ? (
          <>
            <Route exact path={urlRoutes.splash} element={<Splash />} />
            <Route exact path={urlRoutes.home} element={<Home />} />
            <Route exact path={urlRoutes.login} element={<Login />} />
            <Route exact path={urlRoutes.register} element={<Register />} />
            <Route
              exact
              path={urlRoutes.incidentsDashboard}
              element={<IncidentsDashboard />}
            />
            <Route
              exact
              path={urlRoutes.incidentsForms}
              element={<IncidentsForms />}
            />
            <Route
              exact
              path={urlRoutes.auditReports}
              element={<AuditReports />}
            />
            <Route
              exact
              path={urlRoutes.incidentReports}
              element={<IncidentsReports />}
            />
            <Route
              exact
              path={urlRoutes.incidentsReportsMapping}
              element={<IncidentsReportsMapping />}
            />
            <Route
              exact
              path={urlRoutes.incidentsReportsEditing}
              element={<IncidentReportsEditing />}
            />
            <Route
              exact
              path={urlRoutes.incidentsReportsPreview}
              element={<IncidentsFormPreview />}
            />
            <Route
              exact
              path={urlRoutes.submittedFormView}
              element={<SubmittedFormView />}
            />
            <Route
              exact
              path={urlRoutes.form}
              element={<IncidentsFormSubmit />}
            />
            <Route
              exact
              path={urlRoutes.locationsDashboard}
              element={<LocationsDashboard />}
            />
            <Route
              exact
              path={urlRoutes.locationsSettings}
              element={<LocationsSettings />}
            />
            <Route
              exact
              path={urlRoutes.usersDashboard}
              element={<UsersDashboard />}
            />
            <Route
              exact
              path={urlRoutes.usersSettings}
              element={<UsersSettings />}
            />
            <Route
              exact
              path={urlRoutes.companyPositions}
              element={<CompanyPositions />}
            />
            <Route
              exact
              path={urlRoutes.setPassword}
              element={<SetPassword />}
            />
            <Route
              exact
              path={urlRoutes.photoGallery}
              element={<PhotoGallery />}
            />
            <Route
              exact
              path={urlRoutes.receiptTracker}
              element={<ReceiptTracker />}
            />
            <Route
              exact
              path={urlRoutes.formBuilder}
              element={<FormBuilder />}
            />
            <Route
              exact
              path={urlRoutes.inventoryManagement}
              element={<InventoryManagement />}
            />
            <Route
              exact
              path={urlRoutes.assetManagement}
              element={<AssetManagement />}
            />
            <Route
              exact
              path={urlRoutes.companySettings}
              element={<CompanySettings />}
            />
            <Route
              exact
              path={urlRoutes.termsOfService}
              element={<TermsOfService />}
            />
            <Route exact path={urlRoutes.privacy} element={<PrivacyPolicy />} />
          </>
        ) : isLoadingPermissions ? (
          <>
            {/* <Route exact path={pageRoutes.register} element={<Register />} /> */}
            <Route
              path="*"
              element={
                <PageLayout>
                  <Box
                    sx={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <LSSpinner />
                  </Box>
                </PageLayout>
              }
            />
          </>
        ) : (
          <>
            <Route exact path={urlRoutes.splash} element={<Splash />} />
            <Route exact path={urlRoutes.login} element={<Login />} />
            <Route exact path={urlRoutes.register} element={<Register />} />
            <Route
              exact
              path={urlRoutes.termsOfService}
              element={<TermsOfService />}
            />
            <Route exact path={urlRoutes.privacy} element={<PrivacyPolicy />} />
            <Route
              exact
              path={urlRoutes.setPassword}
              element={<SetPassword />}
            />
            <Route
              exact
              path={urlRoutes.form}
              element={<IncidentsFormSubmit />}
            />
            <Route path="*" element={<Login />} />
          </>
        )}
      </Routes>
    </Router>
  );
}
