import { Box, ListItemText, Checkbox, MenuItem } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { StyleSheet, View } from "react-native";

import LSModal from "./LSModal";
import { apiPostUserQANotifications } from "../../../shared/hoc/pagesWeb/incidentReporter/utils";
import LSButton, { buttonVariants } from "../buttons/LSButton";
import Card, { cardVariant } from "../cards/Card";
import LSText, { textVariant } from "../text/LSText";

export default function QAUserNotificationsModal({
  visible,
  setVisible,
  users,
  questionAnswerOption,
  handleUpdateQAUserNotifications,
}) {
  const [userNotifications, setUserNotifications] = useState(
    questionAnswerOption.qaUserNotifications || []
  );

  const handleChangeUserNotifications = (user) => {
    const qaUUID = questionAnswerOption.uuid;
    const adminIdx = userNotifications.findIndex(
      (x) => x.userUUID === user.uuid
    );
    if (adminIdx > -1) {
      const tempAdminsArr = [...userNotifications];
      tempAdminsArr.splice(adminIdx, 1);
      setUserNotifications(tempAdminsArr);
    } else {
      const baseObject = questionAnswerOption.typeCode
        ? { questionUUID: qaUUID }
        : { answerOptionUUID: qaUUID };
      setUserNotifications((prevState) => [
        ...prevState,
        { userUUID: user.uuid, ...baseObject },
      ]);
    }
  };

  const handleSaveUserNotifications = async () => {
    const formID = questionAnswerOption.formUUID;
    if (userNotifications.length === 0) {
      const qaUUID = questionAnswerOption.uuid;
      const baseObject = questionAnswerOption.typeCode
        ? { questionUUID: qaUUID }
        : { answerOptionUUID: qaUUID };
      const response = await apiPostUserQANotifications(formID, [baseObject]);
      handleUpdateQAUserNotifications(response);
      setVisible(false);
      return response;
    }
    const response = await apiPostUserQANotifications(
      formID,
      userNotifications
    );
    handleUpdateQAUserNotifications(response);
    setVisible(false);

    return response;
  };

  useEffect(() => {
    setUserNotifications(questionAnswerOption?.qaUserNotifications || []);
  }, [questionAnswerOption]);

  return (
    <LSModal visible={visible} setVisible={() => setVisible(false)}>
      <Card
        variant={cardVariant.primary}
        customStyles={{ width: 310, maxWidth: "100%" }}
      >
        <LSText
          text="User Notifications"
          variant={textVariant.h4}
          customStyles={{ marginBottom: 16 }}
        />
        <LSText
          text="Users who will be notified when this question is answered"
          variant={textVariant.body}
          customStyles={{ marginBottom: 16, textAlign: "center" }}
        />
        <Box
          sx={{
            width: "100%",
            height: "230px",
            overflowY: "scroll",
            border: 1,
            borderRadius: "12px",
          }}
        >
          {users?.map((user) => (
            <MenuItem
              key={user.uuid}
              value={user}
              sx={{ marginLeft: 0, padding: 0 }}
              onClick={() => handleChangeUserNotifications(user)}
            >
              <Checkbox
                checked={
                  userNotifications.findIndex((x) => x.userUUID === user.uuid) >
                  -1
                }
              />
              <ListItemText primary={`${user.lastName}, ${user.firstName}`} />
            </MenuItem>
          ))}
        </Box>
        <View style={styles.buttonsContainer}>
          <LSButton
            onPress={() => setVisible(false)}
            text="Cancel"
            variant={buttonVariants.secondary}
            customStyles={{ marginRight: 16 }}
          />
          <LSButton
            onPress={handleSaveUserNotifications}
            variant={buttonVariants.primary}
            text="Save Changes"
            customStyles={{ flex: 1 }}
          />
        </View>
      </Card>
    </LSModal>
  );
}

QAUserNotificationsModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  handleUpdateQAUserNotifications: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  questionAnswerOption: PropTypes.object.isRequired,
};

const styles = StyleSheet.create({
  buttonsContainer: {
    flexDirection: "row",
    width: "100%",
  },
});
