import { Box, Typography } from "@mui/material";
import React from "react";

import PageLayout from "../../components/layouts/PageLayout";
import { pageAppTypes } from "../constants";

export default function TermsOfService() {
  return (
    <PageLayout pageAppType={pageAppTypes.locations}>
      <Box sx={{ marginTop: "40px" }}>
        <Typography variant="h4">
          Welcome! Thank you for using LinkStep!
        </Typography>
        <Typography variant="h6">1. Introduction</Typography>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            LinkStep, Inc. (“LinkStep,” “we,” “our,” “us”) offers a variety of
            business process, work-flow, and organizational tools as further
            described in its and website, including but not limited to
            www.linkstep.com (the “Website”).
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            These User Terms of Service (“Terms”) are a binding legal contract
            between you and LinkStep and explain the rules governing your use of
            our Service and Website. These Terms apply to you as a user of the
            Service, subject to exceptions that apply to Managed Users (as
            defined and further detailed below in Section 2 (How These Terms
            Apply)). By accessing or using the Service and Website, you
            acknowledge and agree to be bound by these Terms (as applicable).
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            If you do not agree to these Terms, please do not access or use the
            Service or Website.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            We may revise these Terms from time to time by posting a modified
            version on our website. If, in LinkStep’s sole discretion, the
            modifications to these Terms are material, we will provide you with
            reasonable notice prior to the change taking effect, either by
            emailing the email address associated with your company’s
            administrator accounts or by alerting you through the Service and/or
            Website. If you do not agree to or cannot comply with the modified
            Terms, you must stop using the Service and Website. Unless otherwise
            stated elsewhere in these Terms or in our notice, the updated Terms
            will take effect upon their posting and will apply on a
            going-forward basis. Your continued use of the Service and Website
            after any update to these Terms constitutes your acceptance of such
            changes.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "32px" }}>
          <Typography variant="h6">2. How These Terms Apply</Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            By using LinkStep, you fall into one or more of the following three
            different categories of LinkStep users:
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px", paddingLeft: 2 }}>
          <Typography variant="body1">
            <span>&#x2022;</span> “Site Visitors” are users of the Website.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px", paddingLeft: 2 }}>
          <Typography variant="body1">
            <span>&#x2022;</span> “Free Users” use the free/basic version of the
            Service. Free Users have access to a more limited set of Service
            features and functionality than Managed users?. Free Users may have
            their own individual workspaces and/or take part in a free
            multi-user LinkStep domain.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px", paddingLeft: 2 }}>
          <Typography variant="body1">
            <span>&#x2022;</span> “Managed Users” use the Service as part of any
            paid LinkStep subscription plan purchased by a person or entity (the
            “Customer”) who has separately entered into a written agreement with
            LinkStep (the “Customer Agreement”) governing access and use of the
            Service and permitting such Customer to create and configure
            LinkStep so that Managed Users may join.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            As a Managed User, you gain access to the Service through a Customer
            of LinkStep. For example, if you are joining your employer’s
            organization, Customer is your employer. If you are joining a
            workspace created by your friend using a personal email address,
            your friend is the LinkStep Customer and is authorizing you to join
            his or her workspace. The Customer Agreement governs our
            relationship and commitment to deliver the Service to that Customer,
            who may then invite Managed Users to join their LinkStep instance.
            When you or another Managed User submit content or information to
            the Service, such as messages or files (“Customer Data”), you
            acknowledge and agree that, as between LinkStep and Customer, the
            Customer Data is controlled by Customer and the Customer Agreement
            provides Customer with choices and control over that Customer Data.
            For example, Customer may manage permissions, enable or disable
            third party integrations, or take steps to expand, consolidate or
            share the contents of LinkStep portfolios, projects, tasks and
            subtasks, and these choices and instructions may result in the
            access, use, disclosure, modification or deletion of certain or all
            Customer Data.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            AS BETWEEN LINKSTEP AND CUSTOMER, YOU ACKNOWLEDGE AND AGREE THAT IT
            IS SOLELY CUSTOMER’S RESPONSIBILITY TO (A) INFORM YOU AND ANY OTHER
            MANAGED USERS OF ANY RELEVANT CUSTOMER POLICIES, PRACTICES AND
            SETTINGS THAT MAY IMPACT THE PROCESSING OF CUSTOMER DATA; (B) OBTAIN
            ANY RIGHTS, PERMISSIONS OR CONSENTS FROM YOU AND ANY OTHER MANAGED
            USERS THAT ARE NECESSARY FOR THE LAWFUL USE OF CUSTOMER DATA AND THE
            OPERATION OF THE SERVICE; (C) ENSURE THAT THE TRANSFER AND
            PROCESSING OF CUSTOMER DATA UNDER THE CUSTOMER AGREEMENT IS LAWFUL;
            AND (D) RESPOND TO AND RESOLVE ANY DISPUTE WITH YOU AND ANY OTHER
            MANAGED USERS RELATING TO CUSTOMER DATA, THE SERVICE OR CUSTOMER’S
            FAILURE TO FULFILL THESE OBLIGATIONS. IN YOUR CAPACITY AS AN MANAGED
            USER, LINKSTEP MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND
            (WHETHER EXPRESS OR IMPLIED) TO YOU RELATING TO THE SERVICE, WHICH
            IS PROVIDED TO YOU ON AN “AS IS” AND “AS AVAILABLE” BASIS.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "32px" }}>
          <Typography variant="h6">3. Eligibility and Scope</Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            To use the Service and Website you must be, and you represent and
            warrant that you are, at least 16 years of age and competent to
            agree to these Terms. If the law where you reside requires that you
            must be older in order for LinkStep to lawfully provide the Service
            and Website to you and use your personal data without parental
            consent, then you must be that older age.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            If the representations in the preceding sentence are not true, or if
            LinkStep has previously prohibited you from accessing or using the
            Service and Website, you may not access or use the Service and
            Website.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "32px" }}>
          <Typography variant="h6">3. Eligibility and Scope</Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            To use the Service and Website you must be, and you represent and
            warrant that you are, at least 16 years of age and competent to
            agree to these Terms. If the law where you reside requires that you
            must be older in order for LinkStep to lawfully provide the Service
            and Website to you and use your personal data without parental
            consent, then you must be that older age.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            If the representations in the preceding sentence are not true, or if
            LinkStep has previously prohibited you from accessing or using the
            Service and Website, you may not access or use the Service and
            Website.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "32px" }}>
          <Typography variant="h6">4. Account Registration and Use</Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            4.1 Account Registration and Confidentiality. To access the Service
            and certain parts of our Website, you must register for a LinkStep
            account by creating a username and password. You agree to provide us
            with accurate, complete, and current registration information about
            yourself. It is your responsibility to ensure that your password
            remains confidential and secure, and you agree that you will not
            allow others to access the Service using your LinkStep account. By
            registering, you agree that you are fully responsible for all
            activities that occur under your user name and password. We may
            assume that any communications we receive under your account have
            been made by you. If you are a workspace or organization owner or
            administrator, or if you have confirmed in writing that you have the
            authority to make decisions on behalf of a workspace or organization
            (“Account Administrator”), you represent and warrant that you are
            authorized to to do so and agree that LinkStep is entitled to rely
            on your instructions.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            4.2 Unauthorized Account Use. You are responsible for notifying us
            at support@linkstep.com if you become aware of any unauthorized use
            of or access to your account. You understand and agree that we may
            require you to provide information that may be used to confirm your
            identity and help ensure the security of your account. LinkStep will
            not be liable for any loss, damages, liability, expenses or
            attorneys’ fees that you may incur as a result of someone else using
            your password or account, either with or without your knowledge
            and/or authorization, and regardless of whether you have advised us
            of such unauthorized use. You will be liable for losses, damages,
            liability, expenses and attorneys’ fees incurred by LinkStep or a
            third party due to someone else using your account. In the event
            that the Account Administrator or Customer loses access to an
            account or otherwise requests information about an account, LinkStep
            reserves the right in its sole discretion to request from the
            Account Administrator or Customer any verification it deems
            necessary before restoring access to or providing information about
            such account.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "32px" }}>
          <Typography variant="h6">5. License</Typography>
        </Box>

        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            5. License. Subject to your compliance with these Terms, we grant
            you a limited, non-exclusive, non-sublicensable, non-transferable,
            and revocable right to access and use the Service and Website only
            for your own internal use (or for internal uses authorized by the
            applicable Account Administrator), and only in a manner that
            complies with these Terms and all legal requirements that apply to
            you or your use of the Service and Website. LinkStep may revoke this
            license at any time, in its sole discretion.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "32px" }}>
          <Typography variant="h6">6. Acceptable Use Policy</Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            6. Acceptable Use Policy. You acknowledge and agree to comply with
            these Terms, including the following rules regarding acceptable use
            of the Service and Website (the “Acceptable Use Policy”).
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            6.1 Disruption of the Service. You may not:
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px", paddingLeft: 2 }}>
          <Typography variant="body1">
            <span>&#x2022;</span> access, tamper with, or use non-public areas
            of the Service and Website, LinkStep’s computer systems, or the
            technical delivery systems of LinkStep’s providers;
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px", paddingLeft: 2 }}>
          <Typography variant="body1">
            <span>&#x2022;</span> probe, scan, or test the vulnerability of any
            system or network or circumvent any security measure;
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px", paddingLeft: 2 }}>
          <Typography variant="body1">
            <span>&#x2022;</span> access or search the Service and Website by
            any means other than LinkStep’s publicly supported interfaces (for
            example, “scraping”); or
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px", paddingLeft: 2 }}>
          <Typography variant="body1">
            <span>&#x2022;</span> interfere with or disrupt, or attempt to
            interfere with or disrupt, our infrastructure or the access of any
            user, host or network, including, without limitation, by sending a
            virus, overloading, flooding, spamming, mail-bombing the Service and
            Website, or by scripting the creation of User Content interferes
            with the Service and Website; or
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px", paddingLeft: 2 }}>
          <Typography variant="body1">
            <span>&#x2022;</span> prompt or otherwise attempt to use artificial
            intelligence (AI) models to act in a manner that violates these
            Terms or intentionally circumvents safety filters and functionality
            of the Service.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            6.2 Misuse of the Service and Website. You may not use the Service
            and Website to carry out, promote or support:
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px", paddingLeft: 2 }}>
          <Typography variant="body1">
            <span>&#x2022;</span> any disinformation, deception, or otherwise
            fraudulent activities;
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px", paddingLeft: 2 }}>
          <Typography variant="body1">
            <span>&#x2022;</span> the impersonation of another person or entity
            or the misrepresentation of an affiliation with a person or entity
            (e.g., “spoofing”, “phishing”);
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px", paddingLeft: 2 }}>
          <Typography variant="body1">
            <span>&#x2022;</span> activities that are defamatory, libelous or
            threatening, or otherwise constitute hate speech, harassment, or
            stalking;
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px", paddingLeft: 2 }}>
          <Typography variant="body1">
            <span>&#x2022;</span> the violation of any law or the rights of
            others (including unlawful tracking, monitoring, and identification
            or the publishing or sharing of another person’s confidential or
            personal information without their express authorization and
            permission);
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px", paddingLeft: 2 }}>
          <Typography variant="body1">
            <span>&#x2022;</span> for harm or abuse of a minor, including
            grooming and child sexual exploitation;
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px", paddingLeft: 2 }}>
          <Typography variant="body1">
            <span>&#x2022;</span> the sending of unsolicited communications,
            promotions advertisements, or spam;
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px", paddingLeft: 2 }}>
          <Typography variant="body1">
            <span>&#x2022;</span> the publishing or sharing of malicious
            content;
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px", paddingLeft: 2 }}>
          <Typography variant="body1">
            <span>&#x2022;</span> the promotion or advertisement of products or
            services other than your own without appropriate authorization; or
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px", paddingLeft: 2 }}>
          <Typography variant="body1">
            <span>&#x2022;</span> the development of services that compete with
            LinkStep;
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            6.3 User Content. You may not post any User Content on the Service
            or Website (or otherwise make use of the Service or Website) in a
            manner that:
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px", paddingLeft: 2 }}>
          <Typography variant="body1">
            <span>&#x2022;</span> is deceptive, fraudulent, illegal, obscene,
            defamatory, disparaging, libelous, threatening, or pornographic
            (including child pornography, which, upon becoming aware of, we will
            remove and report to law enforcement, including the National Center
            for Missing and Exploited Children);
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px", paddingLeft: 2 }}>
          <Typography variant="body1">
            <span>&#x2022;</span> criticizes others based on their race,
            ethnicity, national origin, religion, sex, gender, sexual
            orientation, disability, or medical condition;
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px", paddingLeft: 2 }}>
          <Typography variant="body1">
            <span>&#x2022;</span> contains any sensitive personal information as
            defined by applicable law (such as financial information, payment
            card numbers, social security numbers, or health information)
            without LinkStep’s prior written consent granted as part of a
            Customer Agreement;
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px", paddingLeft: 2 }}>
          <Typography variant="body1">
            <span>&#x2022;</span> contains viruses, bots, worms, or similar
            harmful materials;
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px", paddingLeft: 2 }}>
          <Typography variant="body1">
            <span>&#x2022;</span> contains any information that you do not have
            a right to make available under law or any contractual or fiduciary
            duty; or
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px", paddingLeft: 2 }}>
          <Typography variant="body1">
            <span>&#x2022;</span> could otherwise cause damage to LinkStep or
            any third party.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            6.4 Acceptable Use Violations. If we reasonably believe a violation
            of this Acceptable Use Policy has occurred or may occur in the near
            future in a manner that may disrupt the Service or Website for our
            Customers or other users, we may suspend or terminate your access to
            the Service and Website, without any liability to us and in addition
            to any other remedies that may be available to us. LinkStep reserves
            the right to notify the applicable Account Administrator of the
            foregoing.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "32px" }}>
          <Typography variant="h6">7. Proprietary Rights</Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            LinkStep and its licensors exclusively own all right, title, and
            interest in and to all intellectual property rights in the Service
            and Website.You agree to abide by all applicable copyright and other
            laws, as well as any additional copyright notices or restrictions
            contained in the Service and Website. All present and future rights
            in and to trade secrets, patents, copyrights, trademarks, service
            marks, know-how, and other proprietary rights of any type under the
            laws of any governmental authority, domestic or foreign, including
            without limitation rights in and to all applications and
            registrations relating to the Service and Website shall, as between
            you and LinkStep, at all times be and remain the sole and exclusive
            property of LinkStep.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "32px" }}>
          <Typography variant="h6">8. User Content and Feedback</Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            8.1 User Content and Submissions on the Service. The Service allows
            you to create tasks and submit associated information, text, files,
            and other materials (collectively, “User Content”) and to share that
            User Content with others. User Content submitted or otherwise made
            available to the Service is subject to the following terms:
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            8.2 Free User Content. Free Users maintain ownership of the User
            Content that they submit to the Service (“Free User Content”). By
            submitting Free User Content, Free Users grant LinkStep a license to
            access, use, copy, reproduce, process, adapt, publish, transmit, and
            display that Free User Content, in order to provide the Service, and
            as permitted by LinkStep’s Privacy Statement, including if required
            to do so by law or in good faith to comply with legal process. We
            reserve the right to remove any Free User Content on the Service
            that violates these Terms or that is otherwise objectionable in
            LinkStep’s sole discretion.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            8.3 Managed User Content on the Service. User Content submitted to
            the Service by Managed Users is Customer Data, which is owned and
            controlled by the Customer, in accordance with the Customer
            Agreement.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            8.4 Feedback. The Service and the Website may have certain features
            that allow you to submit comments, information, and other materials
            (collectively, “Feedback”) to LinkStep, and/or share such Feedback
            with other users, or the public. If you submit Feedback, LinkStep
            may use such Feedback for any purpose without any compensation or
            obligation to you. We reserve the right to remove any Feedback
            posted in our public forums for any reason at our sole discretion.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            8.5 User Content and Feedback Representations. You represent and
            warrant that you have all required rights to submit User Content and
            Feedback without violation or infringement of any third-party
            rights. You understand that LinkStep does not control, and is not
            responsible for, User Content or Feedback, and that by using the
            Service and/or Website, you may be exposed to User Content or
            Feedback from other users that is offensive, indecent, inaccurate,
            misleading, or otherwise objectionable.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "32px" }}>
          <Typography variant="h6">
            9. WARRANTIES, DISCLAIMER, AND LIMITATION OF LIABILITY
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            THE SERVICE AND WEBSITE AND USER CONTENT, WHETHER PROVIDED BY
            LINKSTEP, ITS LICENSORS, ITS VENDORS OR ITS USERS, AND OTHER
            INFORMATION ON OR ACCESSIBLE FROM THE SERVICE AND WEBSITE ARE
            PROVIDED “AS IS” WITHOUT WARRANTY, REPRESENTATION, CONDITION, OR
            GUARANTEE OF ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING BUT
            NOT LIMITED TO ANY IMPLIED WARRANTIES, REPRESENTATIONS, CONDITIONS
            OR GUARANTEES OF QUALITY, MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE, OR NON-INFRINGEMENT, ALL OF WHICH ARE DISCLAIMED TO THE
            FULLEST EXTENT PERMITTED BY LAW. SPECIFICALLY, BUT WITHOUT
            LIMITATION, LINKSTEP DOES NOT WARRANT THAT: (i) THE INFORMATION
            AVAILABLE ON THE SERVICE AND WEBSITE IS FREE OF ERRORS; (ii) THE
            FUNCTIONS OR FEATURES (INCLUDING BUT NOT LIMITED TO MECHANISMS FOR
            THE DOWNLOADING AND UPLOADING OF USER CONTENT) WILL BE
            UNINTERRUPTED, SECURE, OR FREE OF ERRORS; (iii) DEFECTS WILL BE
            CORRECTED, OR (iv) THE SERVICE AND WEBSITE OR THE SERVER(S) THAT
            MAKE THE SERVICE AND WEBSITE AVAILABLE ARE FREE OF VIRUSES OR OTHER
            HARMFUL COMPONENTS. LINKSTEP DOES NOT WARRANT, ENDORSE, GUARANTEE OR
            ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR
            OFFERED BY A THIRD PARTY THROUGH THE SERVICE AND WEBSITE OR ANY
            WEBSITE FEATURED OR LINKED TO THROUGH THE SERVICE AND WEBSITE, AND
            LINKSTEP WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR
            MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF
            PRODUCTS OR SERVICE AND WEBSITE. LINKSTEP WILL NOT BE LIABLE FOR THE
            OFFENSIVE OR ILLEGAL CONDUCT OF ANY THIRD PARTY. YOU VOLUNTARILY
            ASSUME THE RISK OF HARM OR DAMAGE FROM THE FOREGOING. THE FOREGOING
            LIMITATIONS WILL APPLY EVEN IF A REMEDY FAILS OF ITS ESSENTIAL
            PURPOSE AND TO THE FULLEST EXTENT PERMITTED BY LAW.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            IN NO EVENT SHALL LINKSTEP OR ITS AFFILIATES, LICENSORS, VENDORS, OR
            ANY OF THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, OR
            OTHER REPRESENTATIVES BE LIABLE TO YOU OR ANY OTHER PERSON OR ENTITY
            FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE
            DAMAGES (INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS,
            LOSS OF DATA, LOSS OF USE, OR COSTS OF OBTAINING SUBSTITUTE GOODS OR
            SERVICES), ARISING OUT OF OR IN CONNECTION WITH THE SERVICE AND
            WEBSITE, ANY MATERIALS, INFORMATION, OR RECOMMENDATIONS APPEARING ON
            THE SERVICE AND WEBSITE, OR ANY LINK PROVIDED ON THE SERVICE AND
            WEBSITE, WHETHER OR NOT LINKSTEP HAS BEEN ADVISED OF THE POSSIBILITY
            OF SUCH DAMAGES AND WHETHER BASED UPON WARRANTY, CONTRACT, TORT,
            STRICT LIABILITY, VIOLATION OF STATUTE, OR OTHERWISE. THIS EXCLUSION
            OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW. IN
            ANY EVENT, OUR AGGREGATE LIABILITY WILL NOT EXCEED $100.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            Some countries and U.S. jurisdictions do not allow the exclusion of
            certain warranties or the limitation or exclusion of liability for
            incidental or consequential damages such as above in this section 9.
            Accordingly, some of the above limitations may not apply to you.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "32px" }}>
          <Typography variant="h6">10. Indemnification</Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            YOU AGREE TO INDEMNIFY, DEFEND, AND HOLD LINKSTEP, ITS AFFILIATES,
            AND ITS RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, MEMBERS,
            SHAREHOLDERS, CONTRACTORS, OR REPRESENTATIVES HARMLESS FROM AND
            AGAINST ANY CLAIM OR DEMAND, INCLUDING WITHOUT LIMITATION,
            REASONABLE ATTORNEYS’ FEES, MADE IN CONNECTION WITH OR ARISING OUT
            OF YOUR USE OF THE SERVICE AND WEBSITE, YOUR CONNECTION TO THE
            SERVICE AND WEBSITE, YOUR VIOLATION OF THE TERMS, YOUR VIOLATION OF
            AN APPLICABLE LAW, YOUR SUBMISSION, POSTING, OR TRANSMISSION OF USER
            CONTENT TO THE SERVICE AND WEBSITE, AND/OR YOUR VIOLATION OF ANY
            RIGHTS OF ANOTHER INDIVIDUAL OR ENTITY. WE RESERVE THE RIGHT TO
            ASSUME THE EXCLUSIVE DEFENSE AND CONTROL OF SUCH DISPUTES, AND IN
            ANY EVENT YOU WILL COOPERATE WITH US IN ASSERTING ANY AVAILABLE
            DEFENSES.{" "}
          </Typography>
        </Box>
        <Box sx={{ marginTop: "32px" }}>
          <Typography variant="h6">
            11. Third-Party Links, Services and Website
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            The Service and Website may include information and content provided
            by third parties, including links to third-party website, resources,
            and/or goods and services. LinkStep is not responsible and will not
            be liable for any damages or losses caused by or relating to, (i)
            any content, advertising, products, or other materials on or
            available from such sites or resources, (ii) the availability of or
            any errors or omissions in such website or resources, or (iii) any
            information handling practices or other business practices of the
            operators of such sites or resources. Your interactions with such
            third parties will be governed by the third parties’ own terms of
            service and privacy policies, and any other similar terms.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "32px" }}>
          <Typography variant="h6">12. General Terms</Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            12.1 Modifications to the Service. We reserve the right at any time
            to modify or discontinue, temporarily or permanently, the Service
            and Website (or any part thereof), with or without notice. You agree
            that LinkStep shall not be liable to you or any third party for any
            modification, suspension or discontinuation of the Service and
            Website.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            12.2 Controlling Law. These Terms will be governed by the laws of
            California notwithstanding its conflicts of law principles. However,
            some countries (including those in the European Union) have laws
            that require agreements to be governed by the local laws of the
            consumer’s country. This paragraph does not override those laws.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            12.3 Initial Dispute Resolution. Most disputes can be resolved
            without resort to formal dispute resolution. If you take any issue
            with us or our Service, you agree that before taking any formal
            action, you will contact us at dispute-notice@linkstep.com and
            provide a brief, written description of the dispute and your contact
            information (including your username, if your dispute relates to an
            account). Except for intellectual property and small claims court
            claims, the parties agree to use their best efforts to settle any
            dispute, claim, question, or disagreement directly through
            consultation with LinkStep, and good faith negotiations shall be a
            condition to either party initiating a lawsuit.{" "}
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            12.4 No Waiver. The failure of LinkStep to exercise or enforce any
            right or remedy in these Terms does not waive that right or remedy.
            If any provision of these Terms is found to be invalid or
            unenforceable, the parties agree that the court should endeavor to
            give effect, to the maximum extent permitted by law, to the parties’
            intentions as reflected in the provision, and the other provisions
            of these Terms will remain in full force and effect.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            12.5 Third-Party Beneficiaries. You agree that, except as otherwise
            expressly provided in these Terms, there shall be no third-party
            beneficiaries to these Terms.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            12.6 Entire Agreement. These Terms (and all terms and conditions
            incorporated herein) constitute the entire agreement between you and
            LinkStep, and supersede any prior agreements between you and
            LinkStep on the subject matter. To the extent of any conflict or
            inconsistency between the provisions in these Terms and any other
            terms or resources referenced in these Terms, the terms contained
            directly in these Terms will first prevail; provided, however, that
            if there is a conflict or inconsistency between an applicable
            Customer Agreement and these Terms, the terms of the Customer
            Agreement will first prevail, followed by the provisions in these
            Terms, and then followed by the pages referenced in these Terms
            (e.g., the Privacy Statement). The applicable Customer will be
            responsible for notifying Managed Users of those conflicts or
            inconsistencies and until such time the terms set forth herein will
            be binding.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            12.7 Translations. Any non-English translations of these Terms are
            provided for convenience only. In the event of any ambiguity or
            conflict between translations, the English version shall control.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            12.8 Miscellaneous. These Terms, and any rights or licenses granted
            hereunder, may not be assigned or delegated by you. These Terms, and
            any rights or licenses granted hereunder, may be assigned or
            delegated by LinkStep without restriction. These Terms bind and
            inure to the benefit of each party and the party’s successors and
            permitted assigns. These Terms may not be modified by an oral
            statement by a representative of LinkStep. No agency, partnership,
            joint venture or employee-employer relationship is intended or
            created by these Terms. You agree that any agreements made by and
            between you and us in electronic form are as legally binding as if
            made in physical written form. If you are using the Service and
            Website for or on behalf of the U.S. government, your license rights
            do not exceed those granted to non-government consumers. The section
            titles in these Terms are for convenience only and have no legal or
            contractual effect. Any provision of these Terms that by its nature
            is reasonably intended to survive beyond their termination or
            expiration shall survive. Notwithstanding the generality of the
            foregoing, the following sections shall survive any termination or
            expiration of these Terms: Sections 1 (Introduction); 2 (How These
            Terms Apply); 7 (Proprietary Rights); 8 (User Content and Feedback);
            9 (Warranties, Disclaimer, and Limitation of Liability); 10
            (Indemnification); and 12 (General Terms).
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            12.9 Notices. We may deliver notice to you by email, posting a
            notice on the Service and Website or any other method we choose and
            such notice will be effective on dispatch. If you give notice to us,
            it will be effective when received and you must use the following
            email address: legal@linkstep.com.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "32px" }}>
          <Typography variant="h6">13. Questions</Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            If you have any questions about these Terms, please contact us at
            support@linkstep.com.
          </Typography>
        </Box>
      </Box>
    </PageLayout>
  );
}
