import {
  Paper,
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { urlRoutes } from "./constants";
import LSLogo from "../../assets/StackedLogo.svg";
import { useUser } from "../../context/AuthContext";
import { GET_MY_PERMISSIONS } from "../../shared/apiUrls";
import { isEmailRegex } from "../../shared/components/RegisterComponents/constants";
import { apiGetAuth } from "../../shared/hoc/pagesWeb/incidentReporter/utils";
import { isWeb } from "../../utils/environments";
import LSButton from "../components/buttons/LSButton";
import LSInput from "../components/formInputs/LSInput";
import PageLayout from "../components/layouts/PageLayout";
import LSText, { textVariant } from "../components/text/LSText";
import { setPermissions } from "../redux/permissions";
import { mockNavigationWeb } from "../utils/mockNavigationWeb";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const welcomeText = "Welcome";
const loginTest = "Please login";

const emptyLogin = {
  email: "",
  password: "",
};

const loginError = {
  email: false,
  password: false,
};

export default function Login() {
  const dispatch = useDispatch();
  const [user, setUser] = useState(emptyLogin);
  const [showPassword, setShowPassword] = useState(false);
  const [errorObj, setErrorObj] = useState(loginError);
  const [loginErrors, setLoginErrors] = useState("");
  const { login } = useUser();
  const navigate = useNavigate();
  const { useNavigation } = isWeb
    ? mockNavigationWeb
    : // eslint-disable-next-line global-require
      require("@react-navigation/native");

  const navigation = useNavigation();

  const validateSignupData = () => {
    let errors = false;
    if (!isEmailRegex.test(user.email)) {
      setErrorObj((prevState) => ({
        ...prevState,
        email: true,
      }));
      errors = true;
    } else {
      setErrorObj((prevState) => ({
        ...prevState,
        email: false,
      }));
    }
    if (user.password.length < 8) {
      setErrorObj((prevState) => ({
        ...prevState,
        password: true,
      }));
      errors = true;
    } else {
      setErrorObj((prevState) => ({
        ...prevState,
        password: false,
      }));
    }
    return errors;
  };

  const getSetPermissions = async () => {
    const response = await apiGetAuth(GET_MY_PERMISSIONS);
    dispatch(setPermissions(response));
    return "Success";
  };

  const handleChangeUser = (e) => {
    const { name, value } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleLogin = async () => {
    const errors = validateSignupData();
    if (errors) return;

    const response = await login(user);
    if (response === "Wrong Email or Password" || response === "error") {
      setLoginErrors(response);
      return;
    }
    setLoginErrors("");

    if (isWeb) {
      await getSetPermissions();
      if (window.location.pathname === urlRoutes.login) {
        navigate(urlRoutes.home);
      }
    } else {
      navigation.navigate("home");
    }
  };

  return (
    <PageLayout center>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          minHeight: "85vh",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Paper
          elevation={2}
          sx={{
            padding: 2,
            display: "flex",
            flexDirection: "column",
            width: "330px",
          }}
        >
          <Box
            sx={{
              marginBottom: "px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={LSLogo}
                style={{
                  height: "120px",
                  width: "190px",
                }}
                alt="LinkStep Logo"
              />
            </Box>
            <LSText
              text={welcomeText}
              variant={textVariant.h2}
              customStyles={{
                textAlign: "center",
                marginTop: "24px",
                marginBottom: "24px",
              }}
            />
            <LSText
              text={loginTest}
              variant={textVariant.h4}
              customStyles={{ textAlign: "center" }}
            />
            <LSText text={loginErrors} variant={textVariant.errorCenter} />
          </Box>
          <Box sx={{ marginX: 1 }}>
            <FormControl
              sx={{ width: "100%", my: 2 }}
              variant="outlined"
              size="small"
            >
              <InputLabel htmlFor="outlined-adornment-email">Email</InputLabel>
              <OutlinedInput
                id="outlined-adornment-email"
                name="email"
                onChange={handleChangeUser}
                error={errorObj.email}
                label="Email"
              />
            </FormControl>
            <FormControl sx={{ width: "100%" }} variant="outlined" size="small">
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                name="password"
                onChange={handleChangeUser}
                error={errorObj.password}
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={
                        showPassword
                          ? "hide the password"
                          : "display the password"
                      }
                      onClick={() => setShowPassword((show) => !show)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
          </Box>
          <Box
            style={{
              display: "flex",
              width: 300,
              paddingLeft: 8,
              paddingRight: 8,
              marginBottom: 8,
              flexDirection: "row",
            }}
          >
            <LSButton
              text="Login"
              onPress={handleLogin}
              customStyles={{ width: "100%" }}
            />
          </Box>
        </Paper>
      </Box>
    </PageLayout>
  );
}
