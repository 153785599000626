import React, { useState, useEffect } from "react";
import { defaultTaskErrors, defaultTaskValues } from "./constants";
import {
  CREATE_NEW_TASK,
  DELETE_SUBMISSION_TASKS,
  EDIT_TASK,
} from "../../shared/apiUrls";
import {
  apiDeleteAuth,
  apiPatchAuth,
  apiPostAuth,
} from "../../shared/hoc/pagesWeb/incidentReporter/utils";

export default function useAddTask(
  selectedTask,
  tasks,
  form,
  formSubmit,
  setTasks,
  onClose
) {
  const [loading, setLoading] = useState(false);
  const [addPhase, setAddPhase] = useState(1);
  const [task, setTask] = useState(
    selectedTask || {
      ...defaultTaskValues,
      order: tasks.length + 2,
    }
  );
  const [taskErrors, setTaskErrors] = useState(defaultTaskErrors);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTask((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePressNext = async () => {
    if (validatePhase()) return;
    if (addPhase === 1) {
      setAddPhase(2);
    } else {
      setLoading(true);
      if (selectedTask) {
        const response = await apiPatchAuth(EDIT_TASK, {
          ...task,
          userUUID: task.userUUID || null,
          assignment: formSubmit ? null : task.assignment,
          positionUUID: task.positionUUID || null,
          questionUUID: task.questionUUID || null,
          formStatusChangeId: task.formStatusChangeId || null,
          taskFormUUID: task.taskFormUUID || null,
          defaultFormUUID: !formSubmit && form?.uuid ? form?.uuid : null,
          formSubmitUUID: formSubmit?.uuid || null,
        });

        const tempArr = [...tasks];
        const taskIdx = tempArr.findIndex((c) => c.uuid === response.uuid);
        tempArr[taskIdx] = response;

        setTasks(tempArr);
      } else {
        const response = await apiPostAuth(CREATE_NEW_TASK, {
          ...task,
          userUUID: task.userUUID || null,
          assignment: formSubmit ? null : task.assignment,
          positionUUID: task.positionUUID || null,
          questionUUID: task.questionUUID || null,
          formStatusChangeId: task.formStatusChangeId || null,
          taskFormUUID: task.taskFormUUID || null,
          defaultFormUUID: !formSubmit && form?.uuid ? form?.uuid : null,
          formSubmitUUID: formSubmit?.uuid || null,
        });

        setTasks((prevState) =>
          response.uuid ? [...prevState, response] : [...prevState]
        );
      }
      onClose();
      setLoading(false);
    }
  };

  const validatePhase = () => {
    let errors = false;
    if (addPhase === 1) {
      if (!task.type) {
        errors = true;
        setTaskErrors((prevState) => ({
          ...prevState,
          type: true,
        }));
      } else {
        setTaskErrors((prevState) => ({
          ...prevState,
          type: false,
        }));
      }
      if (task.type === 1 && !task.taskFormUUID) {
        errors = true;
        setTaskErrors((prevState) => ({
          ...prevState,
          taskFormUUID: true,
        }));
      } else {
        setTaskErrors((prevState) => ({
          ...prevState,
          taskFormUUID: false,
        }));
      }
      if (task.type === 2 && !task.title) {
        errors = true;
        setTaskErrors((prevState) => ({
          ...prevState,
          title: true,
        }));
      } else {
        setTaskErrors((prevState) => ({
          ...prevState,
          title: false,
        }));
      }
      if (task.type === 2 && !task.description) {
        errors = true;
        setTaskErrors((prevState) => ({
          ...prevState,
          description: true,
        }));
      } else {
        setTaskErrors((prevState) => ({
          ...prevState,
          description: false,
        }));
      }
      if (!task.formStatusChangeId && task.formStatusChangeId !== 0) {
        errors = true;
        setTaskErrors((prevState) => ({
          ...prevState,
          formStatusChangeId: true,
        }));
      } else {
        setTaskErrors((prevState) => ({
          ...prevState,
          formStatusChangeId: false,
        }));
      }
    } else if (addPhase === 2) {
      if (!task.assignment && !formSubmit) {
        errors = true;
        setTaskErrors((prevState) => ({
          ...prevState,
          assignment: true,
        }));
      } else {
        setTaskErrors((prevState) => ({
          ...prevState,
          assignment: false,
        }));
      }
      if (task.assignment === 1 && !task.userUUID) {
        errors = true;
        setTaskErrors((prevState) => ({
          ...prevState,
          userUUID: true,
        }));
      } else {
        setTaskErrors((prevState) => ({
          ...prevState,
          userUUID: false,
        }));
      }
      if (task.assignment === 2 && !task.questionUUID) {
        errors = true;
        setTaskErrors((prevState) => ({
          ...prevState,
          questionUUID: true,
        }));
      } else {
        setTaskErrors((prevState) => ({
          ...prevState,
          questionUUID: false,
        }));
      }
      if (
        task.assignment === 2 &&
        task.questionUUID &&
        task.isProperty &&
        !task.positionUUID
      ) {
        errors = true;
        setTaskErrors((prevState) => ({
          ...prevState,
          positionUUID: true,
        }));
      } else {
        setTaskErrors((prevState) => ({
          ...prevState,
          positionUUID: false,
        }));
      }
      if (!task.startTime) {
        errors = true;
        setTaskErrors((prevState) => ({
          ...prevState,
          startTime: true,
        }));
      } else {
        setTaskErrors((prevState) => ({
          ...prevState,
          startTime: false,
        }));
      }
      if (!task.completionTime) {
        errors = true;
        setTaskErrors((prevState) => ({
          ...prevState,
          completionTime: true,
        }));
      } else {
        setTaskErrors((prevState) => ({
          ...prevState,
          completionTime: false,
        }));
      }
    }
    return errors;
  };

  const handleDeleteTask = async () => {
    const response = await apiDeleteAuth(
      `${DELETE_SUBMISSION_TASKS}/${task.uuid}`
    );

    const tempArr = [...tasks];
    const taskIdx = tempArr.findIndex((c) => c.uuid === task.uuid);
    tempArr.splice(taskIdx, 1);

    setTasks(tempArr);
    onClose();
  };

  useEffect(() => {
    if (task.questionUUID && task.assignment === 2) {
      const questionIdx = form.questionsList.findIndex(
        (c) => c.uuid === task.questionUUID
      );
      const question = form.questionsList[questionIdx];
      const isProperty = question.answerOptions[0].locationUUID !== null;
      setTask((prevState) => ({
        ...prevState,
        isProperty,
      }));
    } else {
      setTask((prevState) => ({
        ...prevState,
        isProperty: false,
      }));
    }
  }, [task.questionUUID, task.assignment]);

  return {
    loading,
    task,
    addPhase,
    taskErrors,
    handlePressNext,
    handleChange,
    setAddPhase,
    handleDeleteTask,
  };
}
