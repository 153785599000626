import CloseIcon from "@mui/icons-material/Close";
import { LinearProgress } from "@mui/joy";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

import "react-confirm-alert/src/react-confirm-alert.css";
function ModalContainer({ children, title, progress, onClose }) {
  return (
    <div className="react-confirm-alert-body">
      <div className="submit-modal-content">
        <Box
          sx={{
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "row",
            mb: 1,
          }}
        >
          <Typography
            id="submit-modal-header"
            variant="h6"
            sx={{ fontWeight: "bold" }}
          >
            {title}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Stack spacing={2} sx={{ flex: 1 }}>
          <LinearProgress determinate value={progress} />
        </Stack>
        {children}
      </div>
    </div>
  );
}
export default ModalContainer;

ModalContainer.propTypes = {
  children: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  progress: PropTypes.number,
  onClose: PropTypes.func.isRequired,
};

ModalContainer.defaultProps = {
  progress: 100,
};
