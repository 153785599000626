import PropTypes from "prop-types";
import React from "react";
import { useNavigate } from "react-router-dom";

import ModalContainer from "./ModalContainer";
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { urlRoutes } from "../../pages/constants";

function SubmitNewFormModal({ onClose, forms, navigate }) {
  const handleNavigation = (formUUID) => {
    navigate(`${urlRoutes.form}?form=${formUUID}`);
    onClose();
  };

  return (
    <ModalContainer onClose={onClose} title={"Submit Report"}>
      <List
        sx={{
          width: "100%",
          maxWidth: 360,
          maxHeight: 220,
          overflow: "scroll",
          bgcolor: "background.paper",
        }}
      >
        {forms.map((form) => {
          return (
            form.accessType !== 1 &&
            form.accessType !== 4 && (
              <ListItem
                key={`submit-report-modal-${form.uuid}`}
                disableGutters
                disablePadding
              >
                <ListItemButton onClick={() => handleNavigation(form.uuid)}>
                  <ListItemText primary={form.name} />
                </ListItemButton>
              </ListItem>
            )
          );
        })}
      </List>
    </ModalContainer>
  );
}
export default SubmitNewFormModal;

SubmitNewFormModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  forms: PropTypes.array.isRequired,
};
