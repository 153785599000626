import { Button, CircularProgress, Typography } from "@mui/material";
import React, { useState } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import ModalContainer from "./ModalContainer";
import { cancelButtonStyles, submitButtonStyles } from "./styles";

export default function ConfirmActionModal({
  title,
  bodyText,
  handleSubmit,
  onClose,
}) {
  const [loading, setLoading] = useState(false);

  const handleSubmitClick = async () => {
    setLoading(true);
    const response = await handleSubmit();
    console.log({ response });
    onClose();
    setLoading(false);
  };

  return (
    <ModalContainer onClose={onClose} title={title} progress={100}>
      <Typography sx={{ my: 2 }} variant="body2">
        {bodyText}
      </Typography>
      <div className="react-confirm-alert-button-group">
        <Button
          id="confirm-action-modal-submit-button"
          style={{
            ...submitButtonStyles,
            backgroundColor: "#3182CE",
          }}
          onClick={handleSubmitClick}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size="20px" color="inherit" />
          ) : (
            "Submit"
          )}
        </Button>
        <Button
          id="confirm-action-modal-cancel-button"
          onClick={onClose}
          style={{
            ...cancelButtonStyles,
            color: "#3182CE",
          }}
        >
          Cancel
        </Button>
      </div>
    </ModalContainer>
  );
}
