import {
  SupervisorAccount,
  Dashboard,
  FileCopy,
  SettingsApplications,
  GroupAdd,
  Business,
  WarningAmber,
  Report,
  Photo,
  Receipt,
  LocalShipping,
  Inventory,
  Upcoming,
  AccountTree,
  Grading,
} from "@mui/icons-material";

import { urlRoutes } from "../../pages/constants";

export const navItems = [
  {
    name: "Dashboard",
    permissionName: "",
    path: urlRoutes.home,
    icon: <Dashboard />,
  },
  {
    name: "Admin",
    permissionName: "",
    icon: <SupervisorAccount />,
    subItems: [
      {
        name: "Company Profile",
        permissionName: "canAccessCompanyProfilePage",
        path: urlRoutes.companySettings,
        icon: <SettingsApplications />,
      },
      {
        name: "Users",
        permissionName: "canAccessUserPage",
        path: urlRoutes.usersSettings,
        icon: <GroupAdd />,
      },
      {
        name: "Positions",
        permissionName: "canAccessPositionsPage",
        path: urlRoutes.companyPositions,
        icon: <AccountTree />,
      },
      {
        name: "Locations",
        permissionName: "canAccessLocationPage",
        path: urlRoutes.locationsSettings,
        icon: <Business />,
      },
    ],
  },
  {
    name: "Reporting",
    permissionName: "",
    icon: <WarningAmber />,
    subItems: [
      {
        name: "Forms",
        permissionName: "canAccessIncidentFormsPage",
        path: urlRoutes.incidentsForms,
        icon: <FileCopy />,
      },
      {
        name: "Incident Reports",
        permissionName: "canViewSubmittedReports",
        path: urlRoutes.incidentReports,
        icon: <Report />,
      },
      {
        name: "Audit Reports",
        permissionName: "canViewAuditPage",
        path: urlRoutes.auditReports,
        icon: <Grading />,
      },
    ],
  },
  {
    name: "Coming Soon",
    permissionName: "",
    icon: <Upcoming />,
    subItems: [
      {
        name: "Photo Gallery",
        permissionName: "",
        path: urlRoutes.photoGallery,
        icon: <Photo />,
      },
      {
        name: "Inventory Management",
        permissionName: "",
        path: urlRoutes.inventoryManagement,
        icon: <Inventory />,
      },
      {
        name: "Asset Management",
        permissionName: "",
        path: urlRoutes.assetManagement,
        icon: <LocalShipping />,
      },
      {
        name: "Receipt Tracker",
        permissionName: "",
        path: urlRoutes.receiptTracker,
        icon: <Receipt />,
      },
    ],
  },
];

export const hi = "hello";
