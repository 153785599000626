import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState, version } from "react";
import { apiGetAuth } from "../../shared/hoc/pagesWeb/incidentReporter/utils";
import { GET_FORM_VERSIONS } from "../../shared/apiUrls";
import { useNavigate } from "react-router-dom";
import { urlRoutes } from "../pages/constants";

export default function VersionSelector({ formUUID, versionLogUUID }) {
  const navigate = useNavigate();
  console.log({ versionLogUUID });
  const [versions, setVersions] = useState([]);
  const handleChange = (e) => {
    const { value } = e.target;
    console.log({ value });

    navigate(`${urlRoutes.incidentsReportsMapping}?form=${value}`);
  };

  const getVersions = async () => {
    if (!versionLogUUID) return;
    const response = await apiGetAuth(`${GET_FORM_VERSIONS}/${versionLogUUID}`);
    if (response.length) {
      setVersions(response);
    }
  };

  useEffect(() => {
    getVersions();
  }, []);

  return (
    versions.length > 0 && (
      <FormControl size="small" sx={{ mb: 2 }} fullWidth>
        <InputLabel id="demo-simple-select-label">Form Version</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={formUUID}
          label="Form Version"
          onChange={handleChange}
        >
          {versions.map((version) => (
            <MenuItem
              key={version.uuid}
              value={version.uuid}
            >{`Version ${version.version}`}</MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  );
}
