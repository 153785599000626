import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Typography,
  IconButton,
  Collapse,
  InputLabel,
  FormControl,
  Button,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import "react-confirm-alert/src/react-confirm-alert.css";
import { questionTypeCodes } from "../../../shared/hoc/pagesWeb/incidentReporter/constants";
import {
  defaultTaskValues,
  dueOptions,
  startOptions,
  taskAssignmentOptions,
  taskTypes,
} from "../../hooks/constants";

const selectContainerStyles = { mt: 2 };

// eslint-disable-next-line react/prop-types
function AddActionItemModal({ onClose, users, positions, form, forms }) {
  const [task, setTask] = useState(defaultTaskValues);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTask((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (task.questionUUID && task.assignment === 2) {
      const questionIdx = form.questionsList.findIndex(
        (c) => c.uuid === task.questionUUID
      );
      const question = form.questionsList[questionIdx];
      const isProperty = question.answerOptions[0].locationUUID !== null;
      setTask((prevState) => ({
        ...prevState,
        isProperty,
      }));
    } else {
      setTask((prevState) => ({
        ...prevState,
        isProperty: false,
      }));
    }
  }, [task.questionUUID, task.assignment]);

  return (
    <div className="react-confirm-alert-body">
      <div className="submit-modal-content">
        <Box
          sx={{
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "row",
            mb: 1,
          }}
        >
          <Typography
            id="submit-modal-header"
            variant="h6"
            sx={{ fontWeight: "bold" }}
          >
            Create New Default Task
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Box sx={selectContainerStyles}>
          <FormControl fullWidth size="small">
            <InputLabel id="task-type-select-label">Type</InputLabel>
            <Select
              labelId="task-type-select-label"
              label="Type"
              name="type"
              value={task.type}
              sx={{ width: "100%" }}
              onChange={handleChange}
            >
              {taskTypes.map((option) => (
                <MenuItem key={`task-type-${option.key}`} value={option.key}>
                  {option.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Collapse in={task.type === 1}>
          <Box sx={selectContainerStyles}>
            <FormControl fullWidth size="small">
              <InputLabel id="task-user-select-label">Which form?</InputLabel>
              <Select
                labelId="task-user-select-label"
                label="Which form?"
                name="taskFormUUID"
                value={task.taskFormUUID}
                sx={{ width: "100%" }}
                onChange={handleChange}
              >
                {forms.map((_form) => (
                  <MenuItem key={`task-form-${_form.uuid}`} value={_form.uuid}>
                    {_form.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Collapse>
        <Collapse in={task.type === 2}>
          <Box sx={selectContainerStyles}>
            <FormControl fullWidth size="small">
              <TextField
                labelId="task-title-select-label"
                label="Title"
                name="title"
                size="small"
                value={task.title}
                sx={{ width: "100%" }}
                onChange={handleChange}
              />
            </FormControl>
          </Box>
          <Box sx={selectContainerStyles}>
            <FormControl fullWidth>
              <TextField
                labelId="task-description-select-label"
                label="Description"
                name="description"
                size="small"
                multiline
                maxRows={4}
                value={task.description}
                sx={{ width: "100%" }}
                onChange={handleChange}
              />
            </FormControl>
          </Box>
        </Collapse>
        <Box sx={selectContainerStyles}>
          <FormControl fullWidth size="small">
            <InputLabel id="task-assignment-select-label">
              How will the task be assigned?
            </InputLabel>
            <Select
              labelId="task-assignment-select-label"
              label="How will the task be assigned?"
              name="assignment"
              value={task.assignment}
              sx={{ width: "100%" }}
              onChange={handleChange}
            >
              {taskAssignmentOptions.map((option) => (
                <MenuItem
                  value={option.id}
                  key={`taskAssignmentOption-${option.id}`}
                >
                  {option.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Collapse in={task.assignment === 1}>
          <Box sx={selectContainerStyles}>
            <FormControl fullWidth size="small">
              <InputLabel id="task-user-select-label">User</InputLabel>
              <Select
                labelId="task-user-select-label"
                label="User"
                name="userUUID"
                value={task.userUUID}
                sx={{ width: "100%" }}
                onChange={handleChange}
              >
                {users?.map((user) =>
                  user.active !== false ? (
                    <MenuItem key={`task-user-${user.uuid}`} value={user.uuid}>
                      {`${user.lastName} ${user.firstName}`}
                    </MenuItem>
                  ) : (
                    ""
                  )
                )}
              </Select>
            </FormControl>
          </Box>
        </Collapse>
        <Collapse in={task.assignment === 2}>
          <Box sx={selectContainerStyles}>
            <FormControl fullWidth size="small">
              <InputLabel id="task-question-select-label">Question</InputLabel>
              <Select
                labelId="task-question-select-label"
                label="Question"
                name="questionUUID"
                value={task.questionUUID}
                sx={{ width: "100%" }}
                onChange={handleChange}
              >
                {form.questionsList.map((question) =>
                  question.isActive !== false &&
                  question.typeCode === questionTypeCodes.dataSelect ? (
                    <MenuItem
                      key={`task-question-${question.uuid}`}
                      value={question.uuid}
                    >
                      {question.text}
                    </MenuItem>
                  ) : (
                    ""
                  )
                )}
              </Select>
            </FormControl>
          </Box>
        </Collapse>
        <Collapse in={task.isProperty}>
          <Box sx={selectContainerStyles}>
            <FormControl fullWidth size="small">
              <InputLabel id="task-position-select-label">Position</InputLabel>
              <Select
                labelId="task-position-select-label"
                label="Position"
                name="positionUUID"
                value={task.positionUUID}
                sx={{ width: "100%" }}
                onChange={handleChange}
              >
                {positions.map((position) => (
                  <MenuItem
                    key={`task-position-${position.uuid}`}
                    value={position.uuid}
                  >
                    {position.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Collapse>
        <Box sx={selectContainerStyles}>
          <FormControl fullWidth size="small">
            <InputLabel id="task-start-select-label">
              When can the user start?
            </InputLabel>
            <Select
              labelId="task-start-select-label"
              label="When will the task be due?"
              name="startTime"
              value={task.startTime}
              sx={{ width: "100%" }}
              onChange={handleChange}
            >
              {startOptions.map((option) => (
                <MenuItem key={`start-task-${option.id}`} value={option.id}>
                  {option.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={selectContainerStyles}>
          <FormControl fullWidth size="small">
            <InputLabel id="task-due-date-select-label">
              Due after start?
            </InputLabel>
            <Select
              labelId="task-due-date-select-label"
              label="Due date after start?"
              name="completionTime"
              value={task.completionTime}
              sx={{ width: "100%" }}
              onChange={handleChange}
            >
              {dueOptions.map((option) => (
                <MenuItem key={`due-task-${option.id}`} value={option.id}>
                  {option.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <div className="react-confirm-alert-button-group">
          <Button
            id="submit-modal-submit-button"
            style={{
              backgroundColor: "#3182CE",
              color: "white",
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "75px",
            }}
          >
            Submit
          </Button>
          <Button
            id="submit-modal-cancel-button"
            onClick={onClose}
            style={{
              backgroundColor: "white",
              color: "#3182CE",
              border: "solid",
              marginRight: "10px",
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
}
export default AddActionItemModal;

AddActionItemModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  positions: PropTypes.array.isRequired,
  forms: PropTypes.array.isRequired,
  form: PropTypes.object.isRequired,
};
