/* eslint-disable no-nested-ternary */
import { ExpandMore, ExpandLess, Close } from "@mui/icons-material";
import {
  Box,
  Typography,
  IconButton,
  Collapse,
  ListItemText,
  Checkbox,
  MenuItem,
  ListSubheader,
  Button,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useState, useCallback } from "react";
import { StyleSheet } from "react-native";
import { useSelector } from "react-redux";

import LSModal from "./LSModal";
import {
  DEACTIVATE_COMPANY_USER,
  RESEND_INVITATION,
} from "../../../shared/apiUrls";
import {
  apiDeleteAuth,
  apiGetAuth,
} from "../../../shared/hoc/pagesWeb/incidentReporter/utils";
import useUserPermissions from "../../hooks/useUserPermissions";
import { permissionsList, userErrorsObject } from "../../pages/constants";
import LSButton, { buttonVariants } from "../buttons/LSButton";
import Card, { cardVariant } from "../cards/Card";
import LSDropdownPicker from "../formInputs/LSDropdownPicker";
import LSInput, { inputVariants } from "../formInputs/LSInput";
import LSText, { textVariant } from "../text/LSText";

export default function UserSettingsModal({
  visible,
  setVisible,
  user,
  handleEditUserLocation,
  setUser,
  positions,
  locations,
  handleSaveEditUserChanges,
  setUsers,
}) {
  const { canEditUsers, canDeactivateUsers } = useSelector(
    (state) => state.permissions
  );
  const [showSetting, setShowSetting] = useState(1);
  const [confirmDeactivate, setConfirmDeactivate] = useState(false);
  const [confirmResend, setConfirmResend] = useState(false);
  const { userPermissions, handleUpdatePermissions, handleSave } =
    useUserPermissions(user);

  const handleDeactivate = async () => {
    if (confirmDeactivate) {
      const response = await apiDeleteAuth(
        `${DEACTIVATE_COMPANY_USER}/${user.uuid}`
      );

      if (response.uuid) {
        setUsers();
      }

      setConfirmDeactivate(false);
      setVisible(false);
    } else {
      setConfirmDeactivate(true);
    }
  };

  const handleResend = async () => {
    const response = await apiGetAuth(`${RESEND_INVITATION}/${user.uuid}`);
    if (response === "Success") {
      handleClose();
    }
  };

  const handleCancelDeactivate = () => {
    setConfirmDeactivate(false);
    setConfirmResend(false);
  };

  const showPermissions = useCallback(
    () => (
      <Box sx={{ height: "200px", overflow: "scroll" }}>
        {permissionsList?.map((permissionGroup) => (
          <>
            <ListSubheader
              key={permissionGroup.category}
              sx={{
                color: "black",
                fontWeight: "bold",
                borderBottom: 1,
              }}
            >
              {permissionGroup.category}
            </ListSubheader>
            {permissionGroup.permissions.map((permission) => (
              <MenuItem
                key={permission.name}
                value={permission.name}
                sx={{ marginLeft: -3 }}
                onClick={() => handleUpdatePermissions(permission.name)}
              >
                <Checkbox checked={userPermissions?.[permission.name]} />
                <ListItemText primary={`${permission.permissionName}`} />
              </MenuItem>
            ))}
          </>
        ))}
      </Box>
    ),
    [userPermissions]
  );

  const handleOpenSettings = (num) => {
    if (num === showSetting) {
      setShowSetting(0);
    } else {
      setShowSetting(num);
    }
  };

  const handleClose = () => {
    setVisible(false);
    setConfirmDeactivate(false);
    setConfirmResend(false);
    setUser({});
    setShowSetting(1);
  };

  return (
    <LSModal visible={visible} setVisible={handleClose}>
      <Card
        variant={cardVariant.primary}
        customStyles={{ width: 310, maxWidth: "100%" }}
      >
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              borderBottom: 2,
              marginX: -2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              flex: 1,
            }}
          >
            <Box sx={{ width: "50px" }} />
            <LSText
              text={confirmResend ? "Confirm Resend" : "User Settings"}
              variant={textVariant.h2}
              customStyles={{
                marginBottom: 16,
                textAlign: "center",
                flex: 1,
              }}
            />
            <Box sx={{ width: "50px" }}>
              <IconButton onClick={handleClose} sx={{ marginTop: "-2px" }}>
                <Close />
              </IconButton>
            </Box>
          </Box>
          {!confirmDeactivate && !confirmResend ? (
            <>
              <Box
                sx={{
                  borderBottom: 1,
                  borderBottomColor: "lightGray",
                  marginY: 0.5,
                  paddingY: 0.5,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 500, marginTop: "8px" }}
                  >
                    Profile
                  </Typography>
                  <IconButton onClick={() => handleOpenSettings(1)}>
                    {showSetting === 1 ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </Box>
                <Collapse in={showSetting === 1}>
                  <Box>
                    <LSInput
                      variant={inputVariants.primary}
                      setter={setUser}
                      object={user}
                      name="firstName"
                      placeholder="First Name"
                      errorObj={userErrorsObject}
                      customStyles={styles.inputStyles}
                    />
                    <LSInput
                      variant={inputVariants.primary}
                      setter={setUser}
                      object={user}
                      name="lastName"
                      placeholder="Last Name"
                      errorObj={userErrorsObject}
                      customStyles={styles.inputStyles}
                    />
                    <LSInput
                      variant={inputVariants.primary}
                      setter={setUser}
                      object={user}
                      name="email"
                      placeholder="Email"
                      errorObj={userErrorsObject}
                      customStyles={styles.inputStyles}
                    />
                    <LSInput
                      variant={inputVariants.primary}
                      setter={setUser}
                      object={user}
                      name="phone"
                      placeholder="Phone"
                      errorObj={userErrorsObject}
                      customStyles={styles.inputStyles}
                    />
                    <Box sx={{ marginTop: 1.5 }}>
                      <LSDropdownPicker
                        _items={positions.map((p) => ({
                          key: p.uuid,
                          value: p.name,
                        }))}
                        setValue={setUser}
                        name="positionUUID"
                        answerKey="positionUUID"
                        answer={user}
                        error={userErrorsObject.positionUUID}
                      />
                    </Box>
                  </Box>
                </Collapse>
              </Box>
              <Box
                sx={{
                  borderBottom: 1,
                  borderBottomColor: "lightGray",
                  marginY: 0.5,
                  paddingY: 0.5,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 500, marginTop: "8px" }}
                  >
                    Locations
                  </Typography>
                  <IconButton onClick={() => handleOpenSettings(2)}>
                    {showSetting === 2 ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </Box>
                <Collapse in={showSetting === 2}>
                  <Box>
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 500,
                        marginTop: "8px",
                        marginBottom: "16px",
                      }}
                    >
                      Locations {user?.firstName} {user?.lastName} has access
                      to.
                    </Typography>
                    <Box sx={{ height: "200px", overflow: "scroll" }}>
                      {locations?.map((location) => {
                        const locationIdx = user?.locations?.findIndex(
                          (option) => option.uuid === location.uuid
                        );
                        return (
                          <MenuItem
                            key={`edit-user-locations-${location.uuid}`}
                            value={location.uuid}
                            sx={{ marginLeft: -3 }}
                            onClick={() =>
                              handleEditUserLocation(location.uuid)
                            }
                          >
                            <Checkbox checked={locationIdx >= 0} />
                            <ListItemText primary={`${location.name}`} />
                          </MenuItem>
                        );
                      })}
                    </Box>
                  </Box>
                </Collapse>
              </Box>
              <Box
                sx={{
                  borderBottom: 1,
                  borderBottomColor: "lightGray",
                  marginY: 0.5,
                  paddingY: 0.5,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 500, marginTop: "8px" }}
                  >
                    Permissions
                  </Typography>
                  <IconButton onClick={() => handleOpenSettings(3)}>
                    {showSetting === 2 ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </Box>
                <Collapse in={showSetting === 3}>{showPermissions()}</Collapse>
              </Box>
            </>
          ) : (
            <Box sx={{ marginTop: 2 }}>
              <LSText
                text={
                  confirmResend
                    ? `Resend email invitation to ${user.firstName} ${user.lastName}?`
                    : `Are you sure you want to deactivate the account of ${user.firstName} ${user.lastName}?`
                }
                variant={textVariant.h6}
                customStyles={{
                  marginBottom: 16,
                  textAlign: "center",
                  flex: 1,
                }}
              />
            </Box>
          )}
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <LSButton
              text={
                confirmDeactivate || confirmResend
                  ? "Cancel"
                  : user.active || user.active === null
                  ? "Deactivate"
                  : "Reactivate"
              }
              variant={
                confirmDeactivate || confirmResend
                  ? buttonVariants.secondary
                  : buttonVariants.error
              }
              customStyles={{ flex: 1 }}
              onPress={
                confirmDeactivate || confirmResend
                  ? handleCancelDeactivate
                  : handleDeactivate
              }
              disabled={!canDeactivateUsers}
            />
            <LSButton
              text={
                confirmDeactivate
                  ? user.active || user.active === null
                    ? "Deactivate"
                    : "Reactivate"
                  : confirmResend
                  ? "Resend"
                  : "Save Changes"
              }
              variant={
                confirmDeactivate
                  ? buttonVariants.error
                  : buttonVariants.primary
              }
              customStyles={{ flex: 1, marginLeft: 12 }}
              onPress={
                confirmDeactivate || confirmResend
                  ? confirmResend
                    ? handleResend
                    : handleDeactivate
                  : () => {
                      handleSave(userPermissions);
                      handleSaveEditUserChanges();
                      setVisible(false);
                    }
              }
              disabled={!canEditUsers}
            />
          </Box>
          {user.active === null && !confirmResend && !confirmDeactivate && (
            <Button
              variant="text"
              sx={{ marginTop: 1 }}
              onClick={() => setConfirmResend(true)}
              disabled={!canEditUsers}
            >
              Resend Invitation
            </Button>
          )}
        </Box>
      </Card>
    </LSModal>
  );
}

const styles = StyleSheet.create({
  spinnerContainerStyles: {
    height: "80vh",
    justifyContent: "center",
  },
  headerContainerStyles: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
  buttonsContainer: {
    flexDirection: "row",
    width: "100%",
  },
  inputStyles: { margin: 0, marginBottom: 8 },
});

UserSettingsModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
  handleSaveEditUserChanges: PropTypes.func.isRequired,
  handleEditUserLocation: PropTypes.func.isRequired,
  setUsers: PropTypes.func.isRequired,
  user: PropTypes.object,
  locations: PropTypes.array.isRequired,
  positions: PropTypes.array.isRequired,
};

UserSettingsModal.defaultProps = {
  user: {},
};
