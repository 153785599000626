import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  ADD_LOCATION,
  DELETE_LOCATIONS,
  UPDATE_LOCATIONS,
} from "../../shared/apiUrls";
import {
  apiPostAuth,
  apiDeleteAuth,
} from "../../shared/hoc/pagesWeb/incidentReporter/utils";
import { setLocations } from "../redux/locations";

const newLocationObject = {
  name: "",
  address: "",
  city: "",
  state: "",
  zip: "",
};

const locationErrorsObject = {
  name: false,
  address: false,
  city: false,
  state: false,
  zip: false,
};

function useLocations() {
  const [addModalVisible, setAddModalVisible] = useState(false);
  const { locations } = useSelector((state) => state.locations);

  const [confirmDeactivate, setConfirmDeactivate] = useState(false);
  const [locationErrors, setLocationErrors] = useState(locationErrorsObject);
  const [newLocation, setNewLocation] = useState(newLocationObject);
  const [editLocation, setEditLocation] = useState(null);
  const [editLocationErrors, setEditLocationErrors] = useState({});
  const dispatch = useDispatch();

  const validateLocation = () => {
    let errors = false;

    const keys = Object.keys(newLocation);
    keys.forEach((key) => {
      if (!newLocation[key]) {
        setLocationErrors((prevState) => ({
          ...prevState,
          [key]: true,
        }));
        errors = true;
      } else {
        setLocationErrors((prevState) => ({
          ...prevState,
          [key]: false,
        }));
      }
    });

    return errors;
  };

  const validateLocationEdit = () => {
    let errors = false;

    if (!editLocation.name?.trim()) {
      setEditLocationErrors((prevState) => ({
        ...prevState,
        name: true,
      }));
      errors = true;
    } else {
      setEditLocationErrors((prevState) => ({
        ...prevState,
        name: false,
      }));
    }
    if (!editLocation.address?.trim()) {
      setEditLocationErrors((prevState) => ({
        ...prevState,
        address: true,
      }));
      errors = true;
    } else {
      setEditLocationErrors((prevState) => ({
        ...prevState,
        address: false,
      }));
    }
    if (!editLocation.city?.trim()) {
      setEditLocationErrors((prevState) => ({
        ...prevState,
        city: true,
      }));
      errors = true;
    } else {
      setEditLocationErrors((prevState) => ({
        ...prevState,
        city: false,
      }));
    }
    if (!editLocation.state?.trim()) {
      setEditLocationErrors((prevState) => ({
        ...prevState,
        state: true,
      }));
      errors = true;
    } else {
      setEditLocationErrors((prevState) => ({
        ...prevState,
        state: false,
      }));
    }
    if (!editLocation.zip?.trim()) {
      setEditLocationErrors((prevState) => ({
        ...prevState,
        zip: true,
      }));
      errors = true;
    } else {
      setEditLocationErrors((prevState) => ({
        ...prevState,
        zip: false,
      }));
    }

    return errors;
  };

  const handleGenerateLocationPress = async () => {
    const errors = validateLocation();

    if (errors) return;

    const response = await apiPostAuth(ADD_LOCATION, newLocation);
    if (response.name) {
      dispatch(setLocations([response, ...locations]));
      setNewLocation(newLocationObject);
      setAddModalVisible(false);
    }
  };

  const handleEditLocationSubmit = async () => {
    const errors = validateLocationEdit();

    if (errors) return;

    let base64 = "";
    if (editLocation?.files?.length > 0) {
      const reader = new FileReader();
      base64 = await new Promise((resolve, reject) => {
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(editLocation.files[0]);
      });

      base64 = `${base64},${editLocation?.files[0].name}`;
    }

    const response = await apiPostAuth(UPDATE_LOCATIONS, {
      ...editLocation,
      base64,
    });

    if (response.uuid) {
      const idx = locations.findIndex((c) => c.uuid === response.uuid);

      const tempArr = [...locations];
      tempArr[idx] = response;
      dispatch(setLocations(tempArr));
      setAddModalVisible(false);
    }
  };

  const handleDeactivateLocation = async () => {
    if (!confirmDeactivate) {
      setConfirmDeactivate(true);
    } else {
      const response = await apiDeleteAuth(
        `${DELETE_LOCATIONS}/${editLocation.uuid}`
      );
      if (response.uuid) {
        const locationIdx = locations.findIndex(
          (l) => l.uuid === response.uuid
        );
        const tempArr = [...locations];
        tempArr[locationIdx] = response;
        dispatch(setLocations(tempArr));
      }

      setConfirmDeactivate(false);
      setAddModalVisible(false);
    }
  };

  const cancelDeactivate = () => {
    setConfirmDeactivate(false);
  };

  const handleCloseModal = (value) => {
    setAddModalVisible(value);
    setConfirmDeactivate(false);
  };

  return {
    locations,
    locationErrors,
    editLocationErrors,
    newLocation,
    editLocation,
    confirmDeactivate,
    addModalVisible,
    setAddModalVisible: handleCloseModal,
    setNewLocation,
    cancelDeactivate,
    setEditLocation,
    handleGenerateLocationPress,
    handleEditLocationSubmit,
    handleDeactivateLocation,
  };
}

export default useLocations;
