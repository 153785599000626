import React, { useState } from "react";

import PageLayout from "../../components/layouts/PageLayout";
import { pageAppTypes, urlRoutes } from "../constants";
import ReportView from "../../components/ReportView";

const pageTitle = "Audit Reports";

export default function AuditReports() {
  return (
    <PageLayout pageAppType={pageAppTypes.incidentReports}>
      <ReportView pageTitle={pageTitle} type={2} />
    </PageLayout>
  );
}
