import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { REGISTER_COMPANY_URL } from "../../shared/apiUrls";
import { isEmailRegex } from "../../shared/components/RegisterComponents/constants";
import { apiPostAuth } from "../../shared/hoc/pagesWeb/incidentReporter/utils";
import { urlRoutes } from "../pages/constants";

const emptyUserObject = {
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  password: "",
  passwordConfirm: "",
  companyName: "",
  terms: false,
  privacy: false,
};

const userErrorsObject = {
  firstName: false,
  lastName: false,
  phone: false,
  email: false,
  password: false,
  passwordConfirm: false,
  companyName: false,
  terms: false,
  privacy: false,
};

export default function useRegistration() {
  const [user, setUser] = useState(emptyUserObject);
  const [registrationPhase, setRegistrationPhase] = useState(0);
  const [userErrors, setUserErrors] = useState(userErrorsObject);
  const navigate = useNavigate();

  const validation = () => {
    let errors = false;
    if (registrationPhase === 0) {
      if (!user.firstName) {
        errors = true;
        setUserErrors((prevState) => ({
          ...prevState,
          firstName: true,
        }));
      } else {
        setUserErrors((prevState) => ({
          ...prevState,
          firstName: false,
        }));
      }
      if (!user.lastName) {
        errors = true;
        setUserErrors((prevState) => ({
          ...prevState,
          lastName: true,
        }));
      } else {
        setUserErrors((prevState) => ({
          ...prevState,
          lastName: false,
        }));
      }
      if (!user.phone) {
        errors = true;
        setUserErrors((prevState) => ({
          ...prevState,
          phone: true,
        }));
      } else {
        setUserErrors((prevState) => ({
          ...prevState,
          phone: false,
        }));
      }
      if (!isEmailRegex.test(user.email)) {
        errors = true;
        setUserErrors((prevState) => ({
          ...prevState,
          email: true,
        }));
      } else {
        setUserErrors((prevState) => ({
          ...prevState,
          email: false,
        }));
      }
      if (user.password.length < 8) {
        errors = true;
        setUserErrors((prevState) => ({
          ...prevState,
          password: true,
        }));
      } else {
        setUserErrors((prevState) => ({
          ...prevState,
          password: false,
        }));
      }
      if (user.passwordConfirm !== user.password) {
        errors = true;
        setUserErrors((prevState) => ({
          ...prevState,
          passwordConfirm: true,
        }));
      } else {
        setUserErrors((prevState) => ({
          ...prevState,
          passwordConfirm: false,
        }));
      }
    } else if (registrationPhase === 1) {
      if (!user.companyName) {
        errors = true;
        setUserErrors((prevState) => ({
          ...prevState,
          companyName: true,
        }));
      } else {
        setUserErrors((prevState) => ({
          ...prevState,
          companyName: false,
        }));
      }
      if (!user.terms) {
        errors = true;
        setUserErrors((prevState) => ({
          ...prevState,
          terms: true,
        }));
      } else {
        setUserErrors((prevState) => ({
          ...prevState,
          terms: false,
        }));
      }
      if (!user.privacy) {
        errors = true;
        setUserErrors((prevState) => ({
          ...prevState,
          privacy: true,
        }));
      } else {
        setUserErrors((prevState) => ({
          ...prevState,
          privacy: false,
        }));
      }
    }
    return errors;
  };

  const handleCheckBoxClick = (e) => {
    const { name, value } = e.target;

    setUser((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const handlePressNext = async () => {
    if (validation()) return;
    if (registrationPhase === 1) {
      const response = await apiPostAuth(REGISTER_COMPANY_URL, user);

      navigate(urlRoutes.login);
    } else {
      setRegistrationPhase((prevState) => prevState + 1);
    }
  };

  const handlePressBack = () => {
    setRegistrationPhase((prevState) => prevState - 1);
  };

  return {
    user,
    userErrors,
    registrationPhase,
    setUser,
    handlePressNext,
    handlePressBack,
    handleCheckBoxClick,
  };
}
