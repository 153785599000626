import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import useUserLocations from "./useUserLocations";
import { GET_USERS, UPDATE_USER_URL } from "../../shared/apiUrls";
import {
  apiGetAuth,
  apiPatchAuth,
} from "../../shared/hoc/pagesWeb/incidentReporter/utils";
import { setUsers } from "../redux/users";

function useUsers(locations) {
  const { users } = useSelector((state) => state.users);
  const [editUser, setEditUser] = useState();
  const { userLocations } = useUserLocations(editUser);
  const dispatch = useDispatch();

  const getSetUsers = async () => {
    const results = await apiGetAuth(GET_USERS);
    dispatch(setUsers(results));
  };

  const handleEditUserLocation = (locationId) => {
    const existingLocationIdxIdx = editUser.locations.findIndex(
      (location) => location.uuid === locationId
    );

    if (existingLocationIdxIdx >= 0) {
      const tempUserLocationsArr = [...editUser.locations];
      tempUserLocationsArr.splice(existingLocationIdxIdx, 1);

      setEditUser((prevState) => ({
        ...prevState,
        locations: tempUserLocationsArr,
      }));
    } else {
      const locationIdx = locations.findIndex(
        (location) => location.uuid === locationId
      );
      setEditUser((prevState) => ({
        ...prevState,
        locations: [...prevState.locations, locations[locationIdx]],
      }));
    }
  };

  const handleSaveEditUserChanges = async () => {
    await apiPatchAuth(UPDATE_USER_URL, { ...editUser, position: null });
    getSetUsers();
  };

  useEffect(() => {
    setEditUser((prevState) => ({
      ...prevState,
      locations: userLocations,
    }));
  }, [userLocations]);

  return {
    users,
    editUser,
    handleEditUserLocation,
    handleSaveEditUserChanges,
    setEditUser,
    setUsers: getSetUsers,
  };
}

export default useUsers;
