// Tasks
export const defaultTaskValues = {
  assignment: "",
  userUUID: "",
  questionUUID: "",
  positionUUID: "",
  startTime: "",
  completionTime: "",
  title: "",
  type: undefined,
  formStatusChangeId: "",
  taskFormUUID: "",
  description: "",
};

export const defaultTaskErrors = {
  assignment: false,
  userUUID: false,
  questionUUID: false,
  positionUUID: false,
  startTime: false,
  completionTime: false,
  title: false,
  type: false,
  formStatusChangeId: false,
  taskFormUUID: false,
  description: false,
};

export const taskTypes = [
  { key: 1, value: "Submit Another Form" },
  { key: 2, value: "File Upload & Comment" },
];

export const completionTimelineOptions = [
  { key: 1, value: "After Previous Task" },
  { key: 2, value: "Any Time" },
];

export const promptOptions = [
  { key: -1, value: "None" },
  { key: 0, value: "Immediately" },
  { key: 24, value: "24 hours after submission" },
  { key: 48, value: "48 hours after submission" },
  { key: 72, value: "72 hours after submission" },
];

export const taskAssignmentOptions = [
  {
    text: "Same person always",
    id: 1,
  },
  {
    text: "Based on answer to question",
    id: 2,
  },
  {
    text: "Person who submits the form",
    id: 3,
  },
  {
    text: "Manually assign after submission",
    id: 4,
  },
];

export const startOptions = [
  {
    text: "After previous task(s) completion",
    id: 1,
  },
  {
    text: "Anytime after submission",
    id: 2,
  },
];

export const dueOptions = [
  {
    text: "1 Day",
    id: 1,
  },
  {
    text: "2 Days",
    id: 2,
  },
  {
    text: "3 Days",
    id: 3,
  },
  {
    text: "4 Days",
    id: 4,
  },
  {
    text: "5 Days",
    id: 5,
  },
  {
    text: "6 Days",
    id: 6,
  },
  {
    text: "7 Days",
    id: 7,
  },
];

// Questions
export const questionTypeCodes = {
  dropdown: 1,
  textBox: 2,
  multipleChoice: 3,
  calendar: 4,
  paragraph: 5,
  upload: 6,
  datalist: 7,
  formSend: 8,
  rank: 9,
  time: 10,
  datetime: 11,
  radio: 12,
  rate: 13,
  datalistMultiple: 14,
};

export const requiresPhaseTwo = [
  questionTypeCodes.dropdown,
  questionTypeCodes.multipleChoice,
  questionTypeCodes.datalist,
  questionTypeCodes.datalistMultiple,
  questionTypeCodes.radio,
];

export const questionTypes = [
  {
    groupName: "Lists",
  },
  {
    key: questionTypeCodes.dropdown,
    value: "Dropdown Menu",
  },
  {
    key: questionTypeCodes.multipleChoice,
    value: "Multiple Select",
  },
  {
    key: questionTypeCodes.radio,
    value: "Radio",
  },
  {
    key: questionTypeCodes.datalist,
    value: "Data List - Dropdown",
  },
  {
    key: questionTypeCodes.datalistMultiple,
    value: "Data List - Multiple",
  },
  {
    groupName: "Text Input",
  },
  {
    key: questionTypeCodes.textBox,
    value: "Text Box",
  },
  {
    key: questionTypeCodes.paragraph,
    value: "Paragraph",
  },
  {
    groupName: "Date/Time",
  },
  {
    key: questionTypeCodes.calendar,
    value: "Calendar",
  },
  {
    key: questionTypeCodes.time,
    value: "Time",
  },
  {
    key: questionTypeCodes.datetime,
    value: "Date & Time",
  },
  {
    groupName: "Other",
  },
  {
    key: questionTypeCodes.upload,
    value: "File Upload",
  },
  // {
  //   key: questionTypeCodes.formSend,
  //   value: "Form Sender",
  // },
  {
    key: questionTypeCodes.rate,
    value: "Rate",
  },
  // {
  //   key: 9,
  //   value: "Rank",
  // },
];

export const dataListTypes = [
  {
    key: 1,
    value: "Users",
  },
  {
    key: 2,
    value: "Location",
  },
];
