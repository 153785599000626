import { KeyboardArrowUp, KeyboardArrowDown, Add } from "@mui/icons-material";
import { Box, Typography, IconButton, Collapse } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { confirmAlert } from "react-confirm-alert";
import { useSelector } from "react-redux";

import AddActionItemModal from "./modals/AddActionItemModal";
import AddDefaultActionItemModal from "./modals/AddDefaultActionItemModal";
import { taskTypes } from "../hooks/constants";
import useForms from "../hooks/useForms";

export default function ActionItemsBox({
  tasks,
  formSubmit,
  handleNewTaskAdd,
  handleRemoveDeletedTask,
  form,
  setTasks,
  handleSaveReorder,
  admin,
}) {
  const { userUUID } = useSelector((state) => state.permissions);
  const [open, setOpen] = useState(true);
  const [selectedTask, setSelectedTask] = useState(false);
  const [selectedTaskModal, setSelectedTaskModal] = useState(false);
  const [dragItemIndex, setDragItemIndex] = useState();
  const [, setDragOverItemIndex] = useState();
  const { users } = useSelector((state) => state.users);
  const { positions } = useSelector((state) => state.positions);
  const { forms } = useForms();

  const firstPending = tasks?.findIndex((c) => c.status === "Pending");

  const handleDragStart = (idx) => {
    setDragItemIndex(idx);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (idx) => {
    const _tasks = [...tasks];
    const dragItem = _tasks.splice(dragItemIndex, 1)[0];
    _tasks.splice(idx, 0, dragItem);
    const newTasks = _tasks.map((_task, i) => ({
      ..._task,
      order: i + 1,
    }));
    setTasks(newTasks);
    handleSaveReorder(newTasks);
  };

  const handleDragEnter = (idx) => {
    setDragOverItemIndex(idx);
  };

  const handleDragLeave = () => {
    setDragOverItemIndex(undefined);
  };

  const handleDragEnd = () => {
    setDragItemIndex(undefined);
    setDragOverItemIndex(undefined);
  };

  const handleTaskClick = (_task) => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <AddDefaultActionItemModal
          selectedTask={_task}
          formSubmit={formSubmit}
          users={users}
          positions={positions}
          onClose={onClose}
          form={form.uuid ? form : formSubmit.form}
          forms={forms}
          tasks={tasks}
          setTasks={setTasks}
        />
      ),
    });
  };

  const handleOpenModal = () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <AddDefaultActionItemModal
          users={users}
          formSubmit={formSubmit}
          positions={positions}
          onClose={onClose}
          form={form.uuid ? form : formSubmit.form}
          forms={forms}
          tasks={tasks}
          setTasks={setTasks}
        />
      ),
    });
  };

  return (
    <Box
      sx={{
        border: 1,
        borderRadius: 2,
        width: "100%",
        padding: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          borderBottom: open ? 1 : 0,
          paddingBottom: open ? 1 : 0,
        }}
      >
        <Typography variant="h6" sx={{ lineHeight: "40px" }}>
          {`Tasks (${tasks?.length > 0 ? tasks?.length : 0})`}
        </Typography>
        <Box>
          {admin && !admin.isViewOnly && (
            <IconButton onClick={handleOpenModal}>
              <Add />
            </IconButton>
          )}
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
          </IconButton>
        </Box>
      </Box>
      <Collapse in={open}>
        <Box
          sx={{
            maxHeight: "250px",
            overflow: "scroll",
          }}
        >
          {tasks.map((task, idx) => {
            const needsAttention = task.userName === "Assign after submission";
            return admin?.userUUID === userUUID ||
              task.userUUID === userUUID ? (
              <div
                key={`${task.uuid}-${idx}`}
                draggable
                onDragStart={() => handleDragStart(idx)}
                onDragOver={handleDragOver}
                onDrop={() => handleDrop(idx)}
                onDragLeave={handleDragLeave}
                onDragEnd={handleDragEnd}
                onDragEnter={() => handleDragEnter(idx)}
                style={{
                  marginTop: 8,
                  marginBottom: 8,
                  borderRadius: 8,
                }}
              >
                <Box
                  onClick={() => handleTaskClick(task)}
                  sx={{
                    border: 1,
                    borderRadius: 2,
                    padding: 1,
                    backgroundColor:
                      // eslint-disable-next-line no-nested-ternary
                      task.status === "Complete"
                        ? "lightGreen"
                        : idx === firstPending
                        ? "lightBlue"
                        : needsAttention
                        ? "#fcefd1"
                        : "inherit",
                  }}
                >
                  <Typography variant="body2">
                    {task.userName}:{" "}
                    {task.title
                      ? task.title
                      : task.formName ||
                        taskTypes[
                          taskTypes.findIndex((c) => c.key === task.type)
                        ].value}
                  </Typography>
                  <Typography variant="body2">Status: {task.status}</Typography>
                </Box>
              </div>
            ) : (
              ""
            );
          })}
        </Box>
      </Collapse>
    </Box>
  );
}

ActionItemsBox.propTypes = {
  tasks: PropTypes.array.isRequired,
  formSubmit: PropTypes.object.isRequired,
  form: PropTypes.object,
  handleNewTaskAdd: PropTypes.func.isRequired,
  handleRemoveDeletedTask: PropTypes.func.isRequired,
  setTasks: PropTypes.func.isRequired,
  handleSaveReorder: PropTypes.func.isRequired,
  admin: PropTypes.object,
};

ActionItemsBox.defaultProps = {
  form: {},
  admin: {},
};
