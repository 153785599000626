export const submitButtonStyles = {
  backgroundColor: "#3182CE",
  color: "white",
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "75px",
};

export const cancelButtonStyles = {
  backgroundColor: "white",
  color: "#3182CE",
  border: "solid",
  marginRight: "10px",
};
