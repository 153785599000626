// eslint-disable-next-line import/no-extraneous-dependencies
import { configureStore } from "@reduxjs/toolkit";

import locationsReducer from "./locations";
import permissionsReducer from "./permissions";
import positionsReducer from "./positions";
import usersReducer from "./users";

export default configureStore({
  reducer: {
    permissions: permissionsReducer,
    positions: positionsReducer,
    locations: locationsReducer,
    users: usersReducer,
  },
});
