import { Stepper, Step, Paper, StepLabel, Box } from "@mui/material";
import PropTypes from "prop-types";
import * as React from "react";

import { taskTypes } from "../hooks/constants";

export default function ProgressBar({ steps, report }) {
  const firstPendingIdxIdx =
    report.status === "Complete"
      ? steps.length
      : steps.findIndex((c) => c.status === "Pending");

  return (
    steps.length > 0 && (
      <Paper sx={{ width: "100%", padding: 2 }}>
        <Stepper activeStep={firstPendingIdxIdx} alternativeLabel>
          {steps.map((task) => (
            <Step key={`step-${task.uuid}`}>
              <StepLabel>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  {task.userName}:
                </Box>
                {task.title
                  ? task.title
                  : task.formName ||
                    taskTypes[taskTypes.findIndex((c) => c.key === task.type)]
                      .value}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Paper>
    )
  );
}

ProgressBar.propTypes = {
  steps: PropTypes.array.isRequired,
  report: PropTypes.object.isRequired,
};
