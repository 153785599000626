import {
  Paper,
  Box,
  Typography,
  IconButton,
  Button,
  Tooltip,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  MoreVert,
  CheckCircleOutline,
  LockOutlined,
  LockOpenOutlined,
  CancelOutlined,
  Settings,
} from "@mui/icons-material";
import PropTypes from "prop-types";
import React, { useState, version } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  apiLockForm,
  apiPublishForm,
  apiUnlockForm,
  apiUnpublishForm,
} from "../../../shared/components/Sliders/utils";
import { urlRoutes } from "../../pages/constants";
import { accessTypes } from "../../constants/constants";
import ConfirmActionModal from "../modals/ConfirmActionModal";
import { confirmAlert } from "react-confirm-alert";

const successResponse = "Success";
const unlockBodyText =
  "Are you sure you want to unlock this form? Unlocking will enable editing, however, it will create the next version of this form. \n\nReports submitted will continue with the current version until this new version is locked.";
const lockBodyText =
  "Are you sure you want to lock this form? Locking will disable adding, edit, or changes questions and answer options. All future reports will be submitted with this new version.";
const publishBodyText =
  "Are you sure you want to publish? Publishing will make the form available based on it's access level for others to submit reports.";
const unpublishBodyText =
  "Are you sure you want to unpublish? Unpublishing will disable the form availability for others to submit reports.";

const sectionContainerStyles = { display: "flex", flexDirection: "row", mb: 1 };
const headerContainerStyles = { flex: 1, marginRight: 2 };
const sectionHeaderStyles = { fontWeight: 600 };

export default function FormCard({
  form,
  handleOnPressPublish,
  handleOnLockPublish,
  handleOnEditPress,
}) {
  const [showSettings, setShowSetting] = useState(false);
  const { userUUID } = useSelector((state) => state.permissions);
  const { users } = useSelector((state) => state.users);
  console.log({ users, form });
  const isAdminIdx = form?.formAdmins?.findIndex(
    (c) => c.userUUID === userUUID
  );
  const admin = form?.formAdmins[isAdminIdx];

  const navigate = useNavigate();

  const { isPublished, isLocked, uuid, versionLog } = form;
  console.log({ versionLog });
  const handleOnPressPreview = () => {
    navigate(
      `${urlRoutes.form}?previewForm=${versionLog.unlockedVersionUUID || uuid}`
    );
  };

  const handleOnPressMap = () => {
    navigate(
      `${urlRoutes.incidentsReportsMapping}?form=${
        versionLog?.unlockedVersionUUID || uuid
      }`
    );
  };

  const handleOnPressPublishForm = async () => {
    const response = await apiPublishForm(uuid);
    if (response === successResponse) {
      handleOnPressPublish();
    }
  };

  const handleOnPressUnpublish = async () => {
    const response = await apiUnpublishForm(uuid);
    if (response === successResponse) {
      handleOnPressPublish();
    }
  };

  const handleOnPressLockForm = async () => {
    const response = await apiLockForm(uuid);
    if (response === successResponse) {
      handleOnLockPublish();
    }
  };

  const handleOnPressUnlock = async () => {
    const response = await apiUnlockForm(uuid);
    if (response === successResponse) {
      handleOnLockPublish();
    }
  };

  const handleChangeLock = () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmActionModal
          title={`Confirm ${isLocked ? "Unlock" : "Lock"}`}
          bodyText={isLocked ? unlockBodyText : lockBodyText}
          onClose={onClose}
          handleSubmit={isLocked ? handleOnPressUnlock : handleOnPressLockForm}
        />
      ),
    });
  };

  const handleChangePublish = () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmActionModal
          title={`Confirm ${isPublished ? "Unpublish" : "Publish"}`}
          bodyText={isPublished ? unpublishBodyText : publishBodyText}
          onClose={onClose}
          handleSubmit={
            isPublished ? handleOnPressUnpublish : handleOnPressPublishForm
          }
        />
      ),
    });
  };

  const getAdmins = (viewOnly = false) => {
    const admins = [];

    users.forEach((user) => {
      const adminIdx = form.formAdmins.findIndex(
        (c) => c.userUUID === user.uuid
      );
      if (adminIdx >= 0 && form.formAdmins[adminIdx].isViewOnly === viewOnly) {
        admins.push(
          <Typography variant="body2">{`${user.lastName}, ${user.firstName}`}</Typography>
        );
      }
    });
    console.log({ admins });
    return admins.splice(0, 2);
  };

  return (
    <Paper
      elevation={2}
      sx={{
        width: "320px",
        margin: "8px",
        padding: "16px",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
      onMouseLeave={() => setShowSetting(false)}
    >
      <Box sx={{ position: "absolute", top: "-8px", right: "-8px" }}>
        {isLocked && (
          <Tooltip placement="top" title={"Locked"}>
            <LockOutlined color="success" />
          </Tooltip>
        )}
        {isPublished && (
          <Tooltip placement="top" title={"Published"}>
            <CheckCircleOutline color="success" />
          </Tooltip>
        )}
      </Box>
      <Paper
        elevation={4}
        sx={{
          display: showSettings ? "block" : "none",
          minWidth: "150px",
          position: "absolute",
          padding: 1,
          top: "56px",
          right: 0,
          backgroundColor: "white",
        }}
      >
        <MenuList>
          <MenuItem onClick={handleChangeLock}>
            <ListItemIcon>
              {isLocked ? (
                <LockOpenOutlined fontSize="small" />
              ) : (
                <LockOutlined fontSize="small" />
              )}
            </ListItemIcon>
            <ListItemText>{isLocked ? "Unlock" : "Lock"}</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleChangePublish}>
            <ListItemIcon>
              {isPublished ? (
                <CancelOutlined fontSize="small" />
              ) : (
                <CheckCircleOutline fontSize="small" />
              )}
            </ListItemIcon>
            <ListItemText>{isPublished ? "Unpublish" : "Publish"}</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => handleOnEditPress(form)}>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            <ListItemText>Settings</ListItemText>
          </MenuItem>
        </MenuList>
      </Paper>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "start",
          mb: 2,
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Typography variant="h6" sx={{ fontWeight: 700 }}>
            {form.name}
          </Typography>
        </Box>
        <IconButton
          sx={{ mr: -2 }}
          onClick={() => setShowSetting(!showSettings)}
        >
          <MoreVert />
        </IconButton>
      </Box>
      <Box sx={sectionContainerStyles}>
        <Box sx={headerContainerStyles}>
          <Typography variant="body2" sx={sectionHeaderStyles}>
            Admin(s) {form.formAdmins.filter((c) => !c.isViewOnly).length}
          </Typography>
        </Box>
        <Box sx={{ flex: 1 }}>{getAdmins()}</Box>
      </Box>
      <Box sx={sectionContainerStyles}>
        <Box sx={headerContainerStyles}>
          <Typography variant="body2" sx={sectionHeaderStyles}>
            View Admin(s) {form.formAdmins.filter((c) => c.isViewOnly).length}
          </Typography>
        </Box>
        <Box sx={{ flex: 1 }}>{getAdmins(true)}</Box>
      </Box>
      <Box sx={{ ...sectionContainerStyles, flex: 1 }}>
        <Box sx={headerContainerStyles}>
          <Typography variant="body2" sx={sectionHeaderStyles}>
            Access
          </Typography>
        </Box>
        <Box sx={{ flex: 1 }}>
          <Tooltip
            placement="top"
            title={accessTypes[form.accessType - 1].desc}
          >
            <Typography variant="body2">
              {accessTypes[form.accessType - 1].value}
            </Typography>
          </Tooltip>
        </Box>
      </Box>

      <Box direction="row" sx={{ display: "flex" }}>
        <Button
          variant="contained"
          sx={{ flex: 1, marginRight: 2 }}
          onClick={handleOnPressPreview}
        >
          Preview
        </Button>
        <Button variant="outlined" sx={{ flex: 1 }} onClick={handleOnPressMap}>
          Configure
        </Button>
      </Box>
    </Paper>
  );
}

FormCard.propTypes = {
  name: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  questionCount: PropTypes.number.isRequired,
  handleOnPressPublish: PropTypes.func.isRequired,
  handleOnLockPublish: PropTypes.func.isRequired,
  handleOnEditPress: PropTypes.func.isRequired,
};
