/* eslint-disable import/no-named-as-default */
import { FontAwesome } from "@expo/vector-icons";
import { Box, Button } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

import { fontColorDark, green } from "../../../theme/colors";
import UnauthorizedAccess from "../../components/UnauthorizedAccess";
import FormCard from "../../components/cards/FormCard";
import PageLayout from "../../components/layouts/PageLayout";
import AddFormModal from "../../components/modals/AddFormModal";
import LSSpinner from "../../components/spinner/LSSpinner";
import LSText, { textVariant } from "../../components/text/LSText";
import useFormsPage from "../../hooks/useFormsPage";
import useLocations from "../../hooks/useLocations";
import useUsers from "../../hooks/useUsers";
import { pageAppTypes } from "../constants";

const pageTitle = "Forms";

export default function IncidentsForms() {
  const { users } = useUsers();
  const { locations } = useLocations();
  const {
    form,
    forms,
    loading,
    newForm,
    newFormErrors,
    addModalVisible,
    setNewForm,
    handlePressNext,
    handlePressBack,
    handleAddNewForm,
    handleOnEditPress,
    setAddModalVisible,
    handleChangeAdmins,
    handleStatusChange,
    handleChangeAccess,
    handleOnLockPublish,
    handleOnPressPublish,
    handleChangeLocations,
    handleChangeViewAdmins,
    handleUpdateFormSettings,
  } = useFormsPage();
  const { canAccessIncidentFormsPage, canAddNewForms } = useSelector(
    (state) => state.permissions
  );

  return (
    <PageLayout pageAppType={pageAppTypes.incidentReports}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <LSText variant={textVariant.h1} text={pageTitle} color="black" />
        {canAddNewForms && canAccessIncidentFormsPage && (
          <Button
            sx={{
              borderRadius: "50%",
              padding: 2,
              height: "60px",
              width: "40px",
              backgroundColor: green[400],
              "&:hover": {
                backgroundColor: green[100],
                color: "#3c52b2",
              },
            }}
            onClick={handleAddNewForm}
          >
            <FontAwesome name="plus" size={25} color={fontColorDark} />
          </Button>
        )}
      </Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          marginBottom: 50,
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              height: "400px",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LSSpinner />
          </Box>
        ) : !canAccessIncidentFormsPage ? (
          <UnauthorizedAccess />
        ) : (
          forms?.map((_form, idx) => (
            <FormCard
              key={_form.uuid}
              form={_form}
              handleOnPressPublish={() => handleOnPressPublish(idx)}
              handleOnLockPublish={() => handleOnLockPublish(idx)}
              handleOnEditPress={handleOnEditPress}
            />
          ))
        )}
      </Box>
      <AddFormModal
        form={form}
        addModalVisible={addModalVisible}
        setAddModalVisible={setAddModalVisible}
        setNewForm={setNewForm}
        handlePressNext={handlePressNext}
        handlePressBack={handlePressBack}
        handleChangeAdmins={handleChangeAdmins}
        handleChangeViewAdmins={handleChangeViewAdmins}
        handleStatusChange={handleStatusChange}
        handleChangeAccess={handleChangeAccess}
        handleChangeLocations={handleChangeLocations}
        handleUpdateFormSettings={handleUpdateFormSettings}
        newFormErrors={newFormErrors}
        newForm={newForm}
        users={users}
        locations={locations}
      />
    </PageLayout>
  );
}
