import {
  Box,
  Typography,
  IconButton,
  Collapse,
  InputLabel,
  FormControl,
  Button,
  Select,
  MenuItem,
  TextField,
  CircularProgress,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import "react-confirm-alert/src/react-confirm-alert.css";
import { questionTypeCodes } from "../../../shared/hoc/pagesWeb/incidentReporter/constants";
import {
  dueOptions,
  startOptions,
  taskAssignmentOptions,
  taskTypes,
} from "../../hooks/constants";
import useAddTask from "../../hooks/useAddTask";
import ModalContainer from "./ModalContainer";
import { cancelButtonStyles, submitButtonStyles } from "./styles";

const selectContainerStyles = { mt: 2 };
const totalPhases = 2;
function AddDefaultActionItemModal({
  selectedTask,
  onClose,
  users,
  positions,
  form,
  formSubmit,
  forms,
  tasks,
  setTasks,
}) {
  const [showSummary, setShowSummary] = useState(selectedTask ? true : false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const {
    loading,
    task,
    addPhase,
    taskErrors,
    handlePressNext,
    handleChange,
    setAddPhase,
    handleDeleteTask,
  } = useAddTask(selectedTask, tasks, form, formSubmit, setTasks, onClose);

  const statusIdx = form?.statuses?.findIndex(
    (c) => c.id === task?.formStatusChangeId
  );

  return (
    <ModalContainer
      onClose={onClose}
      title={"Default Task"}
      progress={showSummary ? 100 : (addPhase / totalPhases) * 100}
    >
      {confirmDelete ? (
        <Typography mt={1}>
          <b>Confirm you would like to delete this task.</b>
        </Typography>
      ) : showSummary ? (
        <Box sx={{ marginTop: 2 }}>
          <Typography mt={1}>
            <b>Type:</b> {taskTypes[task?.type - 1]?.value}
          </Typography>
          {task?.type === 1 && (
            <Typography mt={1}>
              <b>Form Name:</b> {task?.formName}
            </Typography>
          )}
          {task?.type === 2 && (
            <Typography mt={1}>
              <b>Title:</b> {task?.title}
            </Typography>
          )}
          <Typography mt={1}>
            <b>Description:</b> {task?.description}
          </Typography>
          <Typography mt={1}>
            <b>Status on completion:</b>{" "}
            {form?.statuses[statusIdx]?.name || "No Change"}
          </Typography>
          <Typography mt={1}>
            <b>Assignment:</b>{" "}
            {taskAssignmentOptions[task?.assignment - 1]?.text}
          </Typography>
          <Typography mt={1}>
            <b>User:</b> {task.userName}
          </Typography>
          <Typography mt={1}>
            <b>Start:</b> {startOptions[task?.startTime - 1]?.text}
          </Typography>
          <Typography mt={1}>
            <b>Due:</b>{" "}
            {dueOptions[task?.completionTime - 1]?.text.toLocaleLowerCase()}{" "}
            after submission
          </Typography>
        </Box>
      ) : (
        <>
          <Collapse in={addPhase === 1}>
            <Box sx={selectContainerStyles}>
              <FormControl fullWidth size="small">
                <InputLabel id="task-type-select-label">Type</InputLabel>
                <Select
                  label="Type"
                  name="type"
                  value={task.type || ""}
                  sx={{ width: "100%" }}
                  onChange={handleChange}
                  error={taskErrors.type}
                >
                  {taskTypes?.map((option) => (
                    <MenuItem
                      key={`task-type-${option.key}`}
                      value={option.key}
                    >
                      {option.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Collapse in={task.type === 1}>
              <Box sx={selectContainerStyles}>
                <FormControl fullWidth size="small">
                  <InputLabel id="task-user-select-label">
                    Which form?
                  </InputLabel>
                  <Select
                    label="Which form?"
                    name="taskFormUUID"
                    value={task.taskFormUUID || ""}
                    sx={{ width: "100%" }}
                    onChange={handleChange}
                    error={taskErrors.taskFormUUID}
                  >
                    {forms?.map((_form) => {
                      return _form.type == form.type ? (
                        <MenuItem
                          key={`task-form-${_form.uuid}`}
                          value={_form.uuid}
                        >
                          {_form.name}
                        </MenuItem>
                      ) : (
                        ""
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
            </Collapse>
            <Collapse in={task.type === 2}>
              <Box sx={selectContainerStyles}>
                <FormControl fullWidth size="small">
                  <TextField
                    label="Title"
                    name="title"
                    size="small"
                    value={task.title || ""}
                    sx={{ width: "100%" }}
                    onChange={handleChange}
                    error={taskErrors.title}
                  />
                </FormControl>
              </Box>
            </Collapse>
            <Box sx={selectContainerStyles}>
              <FormControl fullWidth>
                <TextField
                  label="Description"
                  name="description"
                  size="small"
                  multiline
                  maxRows={4}
                  value={task.description || ""}
                  error={taskErrors.description}
                  sx={{ width: "100%" }}
                  onChange={handleChange}
                />
              </FormControl>
            </Box>
            <Box sx={selectContainerStyles}>
              <FormControl fullWidth size="small">
                <InputLabel id="task-due-date-select-label">
                  Change status on task completion
                </InputLabel>
                <Select
                  label="Change status on task completion"
                  name="formStatusChangeId"
                  value={
                    task.formStatusChangeId === null
                      ? 0
                      : task.formStatusChangeId
                  }
                  sx={{ width: "100%" }}
                  onChange={handleChange}
                  error={taskErrors.formStatusChangeId}
                >
                  <MenuItem key="form-status-change-none" value={0}>
                    No Change
                  </MenuItem>
                  {form?.statuses?.map((status) => (
                    <MenuItem
                      key={`form-status-change-${status.id}`}
                      value={status.id}
                    >
                      {status.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Collapse>
          <Collapse in={addPhase === 2}>
            {!formSubmit && (
              <Box sx={selectContainerStyles}>
                <FormControl fullWidth size="small">
                  <InputLabel id="task-assignment-select-label">
                    How will the task be assigned?
                  </InputLabel>
                  <Select
                    label="How will the task be assigned?"
                    name="assignment"
                    value={task.assignment || ""}
                    sx={{ width: "100%" }}
                    onChange={handleChange}
                    error={taskErrors.assignment}
                  >
                    {taskAssignmentOptions?.map((option) => (
                      <MenuItem
                        value={option.id}
                        key={`taskAssignmentOption-${option.id}`}
                      >
                        {option.text}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}
            <Collapse in={task.assignment === 1 || formSubmit}>
              <Box sx={selectContainerStyles}>
                <FormControl fullWidth size="small">
                  <InputLabel id="task-user-select-label">User</InputLabel>
                  <Select
                    label="User"
                    name="userUUID"
                    value={task.userUUID || ""}
                    sx={{ width: "100%" }}
                    onChange={handleChange}
                    error={taskErrors.userUUID}
                  >
                    {users?.map((user) =>
                      user.active === false ? (
                        ""
                      ) : (
                        <MenuItem
                          key={`task-user-${user.uuid}`}
                          value={user.uuid}
                        >
                          {`${user.lastName}, ${user.firstName}`}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Box>
            </Collapse>
            <Collapse in={task.assignment === 2}>
              <Box sx={selectContainerStyles}>
                <FormControl fullWidth size="small">
                  <InputLabel id="task-question-select-label">
                    Question
                  </InputLabel>
                  <Select
                    label="Question"
                    name="questionUUID"
                    value={task.questionUUID || ""}
                    sx={{ width: "100%" }}
                    onChange={handleChange}
                    error={taskErrors.questionUUID}
                  >
                    {form?.questionsList?.map((question) =>
                      question.isActive !== false &&
                      question.typeCode === questionTypeCodes.dataSelect ? (
                        <MenuItem
                          key={`task-question-${question.uuid}`}
                          value={question.uuid}
                        >
                          {question.text}
                        </MenuItem>
                      ) : (
                        ""
                      )
                    )}
                  </Select>
                </FormControl>
              </Box>
            </Collapse>
            <Collapse in={task.isProperty}>
              <Box sx={selectContainerStyles}>
                <FormControl fullWidth size="small">
                  <InputLabel id="task-position-select-label">
                    Position
                  </InputLabel>
                  <Select
                    label="Position"
                    name="positionUUID"
                    value={task.positionUUID || ""}
                    error={taskErrors.positionUUID}
                    sx={{ width: "100%" }}
                    onChange={handleChange}
                  >
                    {positions?.map((position) => (
                      <MenuItem
                        key={`task-position-${position.uuid}`}
                        value={position.uuid}
                      >
                        {position.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Collapse>
            <Box sx={selectContainerStyles}>
              <FormControl fullWidth size="small">
                <InputLabel id="task-start-select-label">
                  When can the user start?
                </InputLabel>
                <Select
                  label="When will the task be due?"
                  name="startTime"
                  value={task.startTime || ""}
                  sx={{ width: "100%" }}
                  onChange={handleChange}
                  error={taskErrors.startTime}
                >
                  {startOptions?.map((option) => (
                    <MenuItem key={`start-task-${option.id}`} value={option.id}>
                      {option.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={selectContainerStyles}>
              <FormControl fullWidth size="small">
                <InputLabel id="task-due-date-select-label">
                  Due after start?
                </InputLabel>
                <Select
                  label="Due date after start?"
                  name="completionTime"
                  value={task.completionTime || ""}
                  sx={{ width: "100%" }}
                  onChange={handleChange}
                  error={taskErrors.completionTime}
                >
                  {dueOptions?.map((option) => (
                    <MenuItem key={`due-task-${option.id}`} value={option.id}>
                      {option.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Collapse>
        </>
      )}
      <div className="react-confirm-alert-button-group">
        <Button
          id="submit-modal-submit-button"
          style={{
            ...submitButtonStyles,
            backgroundColor: confirmDelete ? "red" : "#3182CE",
          }}
          onClick={() =>
            confirmDelete
              ? handleDeleteTask()
              : showSummary
              ? setShowSummary(false)
              : handlePressNext()
          }
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size="20px" color="inherit" />
          ) : confirmDelete ? (
            "Confirm"
          ) : addPhase === 1 ? (
            showSummary ? (
              "Edit"
            ) : (
              "Next"
            )
          ) : (
            "Submit"
          )}
        </Button>
        <Button
          id="submit-modal-cancel-button"
          onClick={() =>
            confirmDelete
              ? setConfirmDelete(false)
              : addPhase == 2
              ? setAddPhase(1)
              : showSummary
              ? setConfirmDelete(true)
              : onClose()
          }
          style={{
            ...cancelButtonStyles,
            color: showSummary && !confirmDelete ? "red" : "#3182CE",
          }}
        >
          {addPhase == 2 || confirmDelete
            ? "Back"
            : showSummary
            ? "Delete"
            : "Cancel"}
        </Button>
      </div>
    </ModalContainer>
  );
}
export default AddDefaultActionItemModal;

AddDefaultActionItemModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  positions: PropTypes.array.isRequired,
  forms: PropTypes.array.isRequired,
  tasks: PropTypes.array.isRequired,
  form: PropTypes.object.isRequired,
  selectedTask: PropTypes.object.isRequired,
  setTasks: PropTypes.func.isRequired,
};
