/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

export const positionsSlice = createSlice({
  name: "positions",
  initialState: { positions: [] },
  reducers: {
    setPositions: (state, action) => {
      state.positions = action.payload;
    },
  },
});

export const { setPositions } = positionsSlice.actions;

export default positionsSlice.reducer;
