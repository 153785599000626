import { Close } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Button,
  Typography,
  MenuItem,
  Checkbox,
  ListItemText,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";

import FormSettingsModalContent from "./FormSettingsModalContent";
import LSModal from "./LSModal";
import ModalMenuItem from "./ModalMenuItem";
import { accessTypes } from "../../constants/constants";
import LSButton, { buttonVariants } from "../buttons/LSButton";
import Card, { cardVariant } from "../cards/Card";
import LSDropdownPicker from "../formInputs/LSDropdownPicker";
import LSInput, { inputVariants } from "../formInputs/LSInput";
import LSText, { textVariant } from "../text/LSText";

const headerStyles = { textAlign: "center", fontWeight: 600 };
const scrollContainerStyles = {
  height: "200px",
  overflow: "scroll",
  borderRadius: 2,
  marginTop: 2,
  border: 1,
  padding: 1,
};

const formTypeOptions = [
  {
    key: 1,
    value: "Incident Reporting",
  },
  {
    key: 2,
    value: "Audit Reporting",
  },
];

export default function AddFormModal({
  form,
  newForm,
  addModalVisible,
  setAddModalVisible,
  handlePressNext,
  handlePressBack,
  handleChangeAdmins,
  handleChangeViewAdmins,
  handleChangeLocations,
  handleUpdateFormSettings,
  handleStatusChange,
  handleChangeAccess,
  newFormErrors,
  setNewForm,
  users,
  locations,
}) {
  const [status, setStatus] = useState({ status: "" });

  const handleAddStatusClick = () => {
    handleStatusChange(status.status);
    setStatus({ status: "" });
  };

  const getFormContent = () => {
    if (addModalVisible === 1) {
      return (
        <>
          <Typography variant="h6" sx={headerStyles}>
            Name & Type
          </Typography>
          <Typography variant="body2">
            <b>Instructions:</b> Set form name and type.{" "}
          </Typography>
          <Box sx={{ width: "100%", marginTop: 2 }} />
          <LSInput
            variant={inputVariants.primary}
            setter={setNewForm}
            object={newForm}
            name="name"
            placeholder="Form Name"
            customStyles={{ margin: 0 }}
            errorObj={newFormErrors}
          />
          <Typography
            variant="caption"
            sx={{
              color: newForm.name?.length > 100 ? "red" : "black",
              textAlign: "end",
            }}
          >
            {`${newForm.name.length} / 100`}
          </Typography>

          <Box sx={{ width: "100%", marginTop: 2 }} />
          <LSDropdownPicker
            _items={formTypeOptions}
            name="type"
            answerKey="type"
            placeholder="Type of Form"
            answer={newForm}
            setValue={setNewForm}
            error={newFormErrors.type}
          />
        </>
      );
    }
    if (addModalVisible === 2) {
      return (
        <>
          <Typography variant="h6" sx={headerStyles}>
            Administrators
          </Typography>
          <Typography variant="body2">
            <b>Instructions:</b> Administrators can add/edit/delete questions,
            tasks, etc.
          </Typography>
          <Box sx={scrollContainerStyles}>
            {users?.map((user) => (
              <MenuItem
                key={user.uuid}
                value={user}
                sx={{ padding: 0 }}
                onClick={() => handleChangeAdmins(user)}
              >
                <Checkbox
                  checked={
                    newForm.admins?.findIndex((x) => x.key === user.uuid) > -1
                  }
                />
                <ListItemText primary={`${user.lastName}, ${user.firstName}`} />
              </MenuItem>
            ))}
          </Box>
          <Typography
            variant="caption"
            sx={{
              color: newFormErrors.admins ? "red" : "black",
            }}
          >
            At least one admin required
          </Typography>
        </>
      );
    }
    if (addModalVisible === 3) {
      return (
        <>
          <Typography variant="h6" sx={headerStyles}>
            View Access
          </Typography>
          <Typography variant="body2">
            <b>Instructions:</b> View Access are people who, along with
            administrators, who can view this form's submissions.
          </Typography>
          <Box sx={scrollContainerStyles}>
            {users?.map((user) => {
              const adminIdx = newForm.admins?.findIndex(
                (c) => c.key === user.uuid
              );
              return (
                <MenuItem
                  key={user.uuid}
                  value={user}
                  sx={{ padding: 0 }}
                  onClick={() =>
                    adminIdx >= 0 ? () => {} : handleChangeViewAdmins(user)
                  }
                >
                  <Checkbox
                    checked={
                      newForm.viewAdmins?.findIndex(
                        (x) => x.key === user.uuid
                      ) > -1
                    }
                    disabled={adminIdx >= 0}
                  />
                  <ListItemText
                    primary={`${user.lastName}, ${user.firstName} ${
                      adminIdx < 0 ? "" : "- Admin"
                    }`}
                  />
                </MenuItem>
              );
            })}
          </Box>
        </>
      );
    }
    if (addModalVisible === 4) {
      return (
        <>
          <Typography variant="h6" sx={headerStyles}>
            Statuses
          </Typography>
          <Typography variant="body2">
            <b>Instructions:</b> Set statuses reports will be able to go
            through, this may include "Pending", "Complete", "Closed", etc. Then
            check the status you want as the default.
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", marginTop: 2 }}>
            <LSInput
              variant={inputVariants.primary}
              setter={setStatus}
              object={status}
              name="status"
              placeholder="New Status"
              customStyles={{ margin: 0, width: "207px" }}
            />
            <Button
              variant="contained"
              size="small"
              sx={{ height: "38px", marginLeft: 1 }}
              onClick={handleAddStatusClick}
            >
              Add
            </Button>
          </Box>
          <Box sx={scrollContainerStyles}>
            {newForm.statuses.map((_status) => (
              <MenuItem key={_status.uuid} value={_status} sx={{ padding: 0 }}>
                <Checkbox
                  checked={_status === newForm.defaultStatus}
                  onClick={() => handleStatusChange(_status, true)}
                />
                <ListItemText primary={_status} />
                <IconButton
                  onClick={() => handleStatusChange(_status, null, true)}
                  sx={{ marginTop: "-2px" }}
                >
                  <Close />
                </IconButton>
              </MenuItem>
            ))}
          </Box>
          <Typography
            variant="caption"
            sx={{
              color: newFormErrors.statuses ? "red" : "black",
            }}
          >
            At least one status required and checked
          </Typography>
        </>
      );
    }
    if (addModalVisible === 5) {
      return (
        <>
          <Typography variant="h6" sx={headerStyles}>
            Form Access
          </Typography>
          <Typography variant="body2">
            <b>Instructions:</b> Who can view and submit this report of this
            form?
          </Typography>
          <Box
            sx={{
              ...scrollContainerStyles,
              overflowY: "visible",
              overflowX: "visible",
            }}
          >
            {accessTypes.map((accessType) => (
              <ModalMenuItem
                key={accessType.key}
                value={accessType}
                onClick={() => handleChangeAccess(accessType.key)}
                checked={newForm.accessType === accessType.key}
                text={accessType.value}
                tooltip={accessType.desc}
              />
            ))}
          </Box>
          <Typography
            variant="caption"
            sx={{
              color: newFormErrors.accessType ? "red" : "black",
            }}
          >
            At least one status required and checked
          </Typography>
        </>
      );
    }
    if (addModalVisible === 6) {
      return (
        <>
          <Typography variant="h6" sx={headerStyles}>
            View Access
          </Typography>
          <Typography variant="body2">
            <b>Instructions:</b> View Access are people who, along with
            administrators, who can view this form's submissions.
          </Typography>
          <Box sx={scrollContainerStyles}>
            {locations.map((location) => (
              <MenuItem
                key={location.uuid}
                value={location}
                sx={{ padding: 0 }}
                onClick={() => handleChangeLocations(location)}
              >
                <Checkbox
                  checked={
                    newForm.locations?.findIndex(
                      (x) => x.key === location.uuid
                    ) > -1
                  }
                />
                <ListItemText primary={location.name} />
              </MenuItem>
            ))}
          </Box>
          <Typography
            variant="caption"
            sx={{
              color: newFormErrors.locations ? "red" : "black",
            }}
          >
            At least one location is required
          </Typography>
        </>
      );
    }
    if (addModalVisible === 7) {
      return (
        <Typography variant="h6" sx={headerStyles}>
          Ready To Save Form?
        </Typography>
      );
    }
    if (addModalVisible === 11) {
      return (
        <FormSettingsModalContent
          form={form}
          users={users}
          closeModal={() => setAddModalVisible(0)}
          handleUpdateFormSettings={handleUpdateFormSettings}
          locations={locations}
        />
      );
    }
    return "";
  };

  return (
    <LSModal visible={Boolean(addModalVisible)} setVisible={setAddModalVisible}>
      <Card
        variant={cardVariant.primary}
        customStyles={{ width: 310, maxWidth: "100%" }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            width: "100%",
            marginBottom: 32,
          }}
        >
          <Box
            sx={{
              borderBottom: 2,
              marginX: -2,
              marginBottom: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              flex: 1,
            }}
          >
            <Box sx={{ width: "60px" }} />
            <LSText
              text={addModalVisible < 11 ? "Add New Form" : "Form Settings"}
              variant={textVariant.h2}
              customStyles={{ marginBottom: 16, flex: 1, textAlign: "center" }}
            />
            <Box sx={{ width: "60px" }}>
              <IconButton
                onClick={() => setAddModalVisible(0)}
                sx={{ marginTop: "-2px" }}
              >
                <Close />
              </IconButton>
            </Box>
          </Box>
          {getFormContent()}
          {addModalVisible < 11 && (
            <Box sx={{ display: "flex", flex: 1 }}>
              <LSButton
                onPress={handlePressBack}
                text={addModalVisible === 1 ? "Cancel" : "Back"}
                variant={buttonVariants.secondary}
                customStyles={{ marginRight: 16, flex: 1 }}
              />
              <LSButton
                onPress={handlePressNext}
                variant={buttonVariants.primary}
                text={addModalVisible < 7 ? "Next" : "Save"}
                customStyles={{ flex: 1 }}
              />
            </Box>
          )}
        </Box>
      </Card>
    </LSModal>
  );
}

AddFormModal.propTypes = {
  form: PropTypes.object.isRequired,
  newForm: PropTypes.object.isRequired,
  addModalVisible: PropTypes.number.isRequired,
  setAddModalVisible: PropTypes.func.isRequired,
  setNewForm: PropTypes.func.isRequired,
  handlePressNext: PropTypes.func.isRequired,
  handlePressBack: PropTypes.func.isRequired,
  handleChangeAdmins: PropTypes.func.isRequired,
  handleStatusChange: PropTypes.func.isRequired,
  handleUpdateFormSettings: PropTypes.func.isRequired,
  handleChangeViewAdmins: PropTypes.func.isRequired,
  handleChangeAccess: PropTypes.func.isRequired,
  handleChangeLocations: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  newFormErrors: PropTypes.object.isRequired,
};
