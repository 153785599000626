import { useState, useEffect } from "react";

import { GET_USER_LOCATIONS } from "../../shared/apiUrls";
import { apiGetAuth } from "../../shared/hoc/pagesWeb/incidentReporter/utils";

export default function useUserLocations(user) {
  const [userLocations, setUserLocations] = useState([]);

  const handleGetUserLocations = async () => {
    if (user?.uuid) {
      const response = await apiGetAuth(`${GET_USER_LOCATIONS}/${user.uuid}`);
      setUserLocations(response);
    }
  };

  useEffect(() => {
    handleGetUserLocations();
  }, [user?.uuid]);

  return { userLocations };
}
