import React, { useState } from "react";

import PageLayout from "../../components/layouts/PageLayout";
import { pageAppTypes, urlRoutes } from "../constants";
import ReportView from "../../components/ReportView";

const pageTitle = "Incident Reports";

export default function IncidentsReports() {
  return (
    <PageLayout pageAppType={pageAppTypes.incidentReports}>
      <ReportView pageTitle={pageTitle} type={1} />
    </PageLayout>
  );
}
