import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import UnauthorizedAccess from "../components/UnauthorizedAccess";
import LSSpinner from "../components/spinner/LSSpinner";
import LSText, { textVariant } from "../components/text/LSText";
import useFormsShort from "../hooks/useFormsShort";
import useSubmissions from "../hooks/useSubmissions";
import { DOWNLOAD_PDF_REPORT } from "../../shared/apiUrls";
import { fontColorDark } from "../../theme/colors";
import { urlRoutes } from "../pages/constants";
import { getFormattedDate } from "../utils/utils";
import SubmitNewFormModal from "./modals/SubmitNewFormModal";
import { confirmAlert } from "react-confirm-alert";

export default function ReportView({ pageTitle, type }) {
  const navigate = useNavigate();
  const { forms, loadingShortForm } = useFormsShort(type);
  const [selectedForm, setSelectedForm] = useState("");
  const { submissions, columnQuestions, loading } = useSubmissions(
    selectedForm,
    type
  );
  console.log({ submissions });
  const { canViewSubmittedReports, canViewAuditPage, canSubmitForms } =
    useSelector((state) => state.permissions);

  const handleFormChange = (event) => {
    setSelectedForm(event.target.value);
  };

  const handleGetColumns = () => {
    const columns = [
      {
        field: "userName",
        headerName: "Submitter",
        width: 150,
        editable: false,
      },
      {
        field: "submitDate",
        headerName: "Date Submitted",
        sortable: true,
        width: 160,
        valueGetter: (value) => `${getFormattedDate(new Date(value))}`,
      },
    ];
    if (selectedForm) {
      for (let i = 0; i < columnQuestions.length; i += 1) {
        columns.push({
          field: columnQuestions[i].columnFieldName,
          headerName: columnQuestions[i].text,
          sortable: true,
          width: 160,
          valueGetter: (value) =>
            columnQuestions[i].typeCode === 4
              ? `${getFormattedDate(new Date(value))}`
              : value,
        });
      }
      columns.push({
        field: "nextTaskUser",
        headerName: "Next Task",
        sortable: true,
        width: 160,
      });
      columns.unshift({
        field: "status",
        headerName: "Status",
        width: 100,
        editable: false,
        renderCell: (params) => (
          <Link to={`${urlRoutes.submittedFormView}?report=${params.id}`}>
            {params.formattedValue}
          </Link>
        ),
      });
    } else {
      columns.unshift({
        field: "status",
        headerName: "Status",
        width: 100,
        editable: false,
      });
      columns.unshift({
        field: "formName",
        headerName: "Form Name",
        width: 350,
        editable: false,
        renderCell: (params) => (
          <Link to={`${urlRoutes.submittedFormView}?report=${params.id}`}>
            {params.formattedValue}
          </Link>
        ),
      });
    }
    columns.push({
      field: "uuid",
      headerName: "PDF",
      width: 100,
      editable: false,
      renderCell: (value) => (
        <a
          href={`${DOWNLOAD_PDF_REPORT}/${value.id}`}
          download={value.row.formName}
        >
          <PictureAsPdfIcon style={{ color: fontColorDark }} />
        </a>
      ),
    });
    return columns;
  };

  const handleNewReportClick = () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <SubmitNewFormModal
          navigate={navigate}
          forms={forms}
          onClose={onClose}
        />
      ),
    });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <LSText variant={textVariant.h1} text={pageTitle} color="Black" />
        {((canSubmitForms && type == 1) || (type === 2 && canViewAuditPage)) &&
          forms?.length > 0 && (
            <Button variant="contained" onClick={handleNewReportClick}>
              Submit New Report
            </Button>
          )}
      </Box>
      {loading && loadingShortForm ? (
        <Box
          sx={{
            height: "80vh",
            justifyContent: "center",
          }}
        >
          <LSSpinner />
        </Box>
      ) : (type === 1 && canViewSubmittedReports) ||
        (type === 2 && canViewAuditPage) ? (
        <Box sx={{ width: "100%" }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <FormControl sx={{ marginTop: 2 }}>
              <InputLabel id="demo-simple-select-label">
                Sort By Form
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedForm}
                label="Sort By Form"
                onChange={handleFormChange}
              >
                <MenuItem value="">
                  <em>10 Most Recent</em>
                </MenuItem>
                {forms.map((form) => (
                  <MenuItem value={form.uuid}>{form.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ backgroundColor: "white", marginTop: "16px" }}>
            {loading ? (
              <LSSpinner />
            ) : (
              <DataGrid
                rows={submissions}
                columns={handleGetColumns()}
                getRowId={(row) => row.uuid}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                disableRowSelectionOnClick
              />
            )}
          </Box>
        </Box>
      ) : (
        <UnauthorizedAccess />
      )}
    </>
  );
}
