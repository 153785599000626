import { Box, Typography, Select, MenuItem, FormControl } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

export default function ReportInfoBox({ report, handleStatusChange, admin }) {
  return (
    <Box>
      <Box
        sx={{
          border: 1,
          borderRadius: 1,
          padding: 1,
        }}
      >
        <Typography
          variant="body2"
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box
            sx={{
              width: "60px",
              alignItems: "center",
              justifyContent: "center",
              pt: "6px",
            }}
          >
            <b>Status: </b>
          </Box>
          <FormControl size="small" fullWidth>
            <Select
              size="small"
              id="demo-simple-select"
              value={report.status}
              onChange={handleStatusChange}
              sx={{ fontSize: "12px", padding: 0 }}
              disabled={Boolean(admin && admin?.isViewOnly) || !admin}
            >
              {report.form.statuses.map((status) => (
                <MenuItem value={status.name} sx={{ marginLeft: 0 }}>
                  {status.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Typography>
        <Typography
          variant="body2"
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box sx={{ width: "60px" }}>
            <b>Date: </b>
          </Box>
          {new Date(
            `${new Date(report?.submitDate).toLocaleString()} UTC`
          ).toLocaleString()}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box sx={{ width: "60px" }}>
            <b>User: </b>
          </Box>
          {`${report?.user?.firstName} ${report?.user?.lastName}`}
        </Typography>
      </Box>
    </Box>
  );
}

ReportInfoBox.propTypes = {
  report: PropTypes.object.isRequired,
  handleStatusChange: PropTypes.func.isRequired,
  admin: PropTypes.object.isRequired,
};
