import { Box, Typography } from "@mui/material";
import React from "react";

import PageLayout from "../../components/layouts/PageLayout";
import { pageAppTypes } from "../constants";

export default function PrivacyPolicy() {
  return (
    <PageLayout pageAppType={pageAppTypes.locations}>
      <Box sx={{ marginTop: "40px" }}>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            Thanks for using LinkStep! Here we describe how we collect, use, and
            handle your personal data when you use our website, software, and
            services (“Services”).
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            We collect and use the following information to provide, improve,
            protect, and promote our Services.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            Account information. We collect, and associate with your account,
            the information you provide to us when you do things such as sign up
            for your account, upgrade to a paid plan, and set up two-factor
            authentication (like your name, email address, phone number, payment
            info, and physical address).
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            Your Stuff. Our Services are designed as a simple and personalized
            way for you to store your files, documents, photos, comments,
            messages, and so on (“Your Stuff”), collaborate with others, and
            work across multiple devices and services. To make that possible, we
            store, process, and transmit Your Stuff as well as information
            related to it. This related information includes your profile
            information that makes it easier to collaborate and share Your Stuff
            with others, as well as things like the size of the file, the time
            it was uploaded, collaborators, and usage activity.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            Contacts. You may choose to give us access to your contacts to make
            it easy for you, and your LinkStep Team if you’re a LinkStep Team
            user, to do things like share and collaborate on Your Stuff, send
            messages, and invite others to use the Services. If you do, we’ll
            store those contacts on our servers.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            Usage information. We collect information related to how you use the
            Services, including actions you take in your account (like sharing,
            editing, viewing, creating and moving files or folders, and sending
            and receiving electronic signature requests and other transactions).
            We use this information to provide, improve, and promote our
            Services, and protect LinkStep users?.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            Device information. We also collect information from and about the
            devices you use to access the Services. This includes things like IP
            addresses, the type of browser and device you use, the web page you
            visited before coming to our sites, and identifiers associated with
            your devices. Your devices (depending on their settings) may also
            transmit location information to the Services. For example, we use
            device information to detect abuse and identify and troubleshoot
            bugs.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            Cookies and other technologies. We use technologies to provide,
            improve, protect, and promote our Services. For example, cookies
            help us with things like remembering your username for your next
            visit, understanding how you are interacting with our Services, and
            improving them based on that information. You can set your browser
            to not accept cookies, but this may limit your ability to use the
            Services. We may also use third-party service providers that set
            cookies and similar technologies to promote LinkStep services.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            LinkStep analytics. When you use the LinkStep we collect information
            including your identifying and device information, such as email
            addresses, IP addresses, and device identifiers of devices you use
            to view the content. We also collect information on how you interact
            with the viewed content, such as the date and time you view the
            content, the number of times and length of time you view the
            content, and which portions of the content you view.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            Marketing. We give users the option to use some of our Services free
            of charge. These free Services are made possible by the fact that
            some users upgrade to one of our paid Services. If you register for
            our Services, we will, from time to time, send you information about
            upgrades when permissible. Users who receive these marketing
            materials can opt out at any time. If you don’t want to receive a
            particular type of marketing material from us, click the
            ‘unsubscribe’ link in the corresponding emails, or update your
            preferences in the Notifications section of your personal account.
            We may also collect information from you if you interact with
            LinkStep representatives at an event, download marketing or
            educational materials from our website, or contact a LinkStep
            representative. We may use the information you provide to send you
            additional marketing materials.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            We sometimes contact people who don’t have a LinkStep account. If
            you receive an email and no longer wish to be contacted by LinkStep,
            you can unsubscribe and remove yourself from our contact list via
            the message itself.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            Bases for processing your data. We collect and use the personal data
            described above in order to provide you with the Services in a
            reliable and secure manner. We also collect and use personal data
            for our legitimate business needs. To the extent we process your
            personal data for other purposes, we ask for your consent in advance
            or require that our partners obtain such consent.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            We may share information as discussed below, but we won’t sell it to
            advertisers or other third parties.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            Others working for and with LinkStep. LinkStep uses certain trusted
            third parties (for example, providers of customer support and IT
            services) for the business purposes of helping us provide, improve,
            protect, and promote our Services. These third parties will access
            your information to perform tasks on our behalf, and we’ll remain
            responsible for their handling of your information per our
            instructions.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            Other LinkStep Companies. LinkStep shares infrastructure, systems,
            and technology with other LinkStep Companies to provide, improve,
            protect, and promote LinkStep Company Services. We process your
            information across the LinkStep Companies for these purposes, as
            permitted by applicable law and in accordance with their terms and
            policies.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            Other users?. Our Services are designed to help you collaborate with
            others. If you register your LinkStep account with an email address
            on a domain owned by your employer or organization, join a LinkStep
            Team, or collaborate with other LinkStep users, we may suggest you
            or your team as a potential collaborator to other users or teams.
            For example, if you interact with a person at a company, and that
            person frequently works with one of their coworkers, we may suggest
            you as a potential collaborator for that coworker. Collaborators and
            potential collaborators may see some of your basic information, like
            your name, LinkStep Team name, profile picture, device, email
            address, and usage information. This helps you sync up with teams
            you can join and helps other users share files and folders with you.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            Certain features let you make additional information available to
            others. For example, if you view a file or folder shared from
            DocSend or LinkStep analytics, we will share your identifying
            information such as name and email address, information on the
            device you used to view the content, and for how long you viewed
            content and what portion of the content you viewed with the owner of
            the file or folder.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            Team Admins. If you are a user of a LinkStep Team, your
            administrator may have the ability to access and control your
            LinkStep account. Please refer to your organization’s internal
            policies if you have questions about this. If you aren’t a LinkStep
            Team user but interact with a LinkStep Team user (for example, by
            joining a shared folder or accessing stuff shared by that user),
            members of that organization may be able to view information about
            you (such as your name, email address, and profile picture) and your
            interaction with the LinkStep Team user (such as your IP address).
            If you share Your Stuff with a LinkStep Team user, the administrator
            of the team account may have the ability to access and edit what you
            share.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            Law & Order and the Public Interest. We may disclose your
            information to third parties if we determine that such disclosure is
            reasonably necessary to: (a) comply with any applicable law,
            regulation, legal process, or appropriate government request; (b)
            protect any person from death or serious bodily injury; (c) prevent
            fraud or abuse of LinkStep or our users; (d) protect LinkStep’s
            rights, property, safety, or interest; or (e) perform a task carried
            out in the public interest.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            Stewardship of your data is critical to us and a responsibility that
            we embrace. We believe that your data should receive the same legal
            protections regardless of whether it’s stored on our Services or on
            your home computer’s hard drive. We’ll abide by the following
            Government Request Principles when receiving, scrutinizing, and
            responding to government requests (including national security
            requests) for your data:
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px", paddingLeft: 2 }}>
          <Typography variant="body1">
            <span>&#x2022;</span> Be transparent
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px", paddingLeft: 2 }}>
          <Typography variant="body1">
            <span>&#x2022;</span> Fight blanket requests
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px", paddingLeft: 2 }}>
          <Typography variant="body1">
            <span>&#x2022;</span> Protect all users, and
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px", paddingLeft: 2 }}>
          <Typography variant="body1">
            <span>&#x2022;</span> Provide trusted services.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            Security. We have a team dedicated to keeping your information
            secure and testing for vulnerabilities. We continue to work on
            features to keep your information safe in addition to things like
            two-factor authentication, encryption of files at rest, and alerts
            when new devices and apps are linked to your account. We deploy
            automated technologies to detect abusive behavior and content that
            may harm our Services, you, or other users?. User Controls. You can
            access, amend, download, and delete your personal information by
            logging into your LinkStep account and going to your account
            settings page.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            Retention. When you sign up for an account with us, we’ll retain
            information you store on our Services for as long as your account
            exists or as long as we need it to provide you the Services. If you
            delete your account, we’ll initiate deletion of this information
            after 30 days. But please note: (1) there might be some latency in
            deleting this information from our servers and back-up storage; and
            (2) we may retain this information if necessary to comply with our
            legal obligations, resolve disputes, or enforce our agreements.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            Around the world. To provide you with the Services, we may store,
            process, and transmit data in the United States and locations around
            the world—including those outside your country. Data may also be
            stored locally on the devices you use to access the Services.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            If we’re involved in a reorganization, merger, acquisition, or sale
            of our assets, your data may be transferred as part of that deal.
            We’ll notify you (for example, via a message to the email address
            associated with your account) of any such deal and outline your
            choices in that event.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            We may revise this Privacy Policy from time to time, and will post
            the most current version on our website. If a revision meaningfully
            reduces your rights, we will notify you.
          </Typography>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            Have questions or concerns about LinkStep, our Services, and
            privacy? Contact our Data Protection Officer at
            privacy@linkstep.com. If they can’t answer your question, you have
            the right to contact your local data protection supervisory
            authority.
          </Typography>
        </Box>
      </Box>
    </PageLayout>
  );
}
